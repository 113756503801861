import moment from 'moment';
import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions(
  {
    fetchParticipant: ['participantId'],
    fetchParticipantSuccess: ['data'],
    refreshParticipant: ['participantId'],
    setRefreshParticipant: ['isRefreshing'],
    fetchPrograms: ['participantId'],
    fetchProgramsSuccess: ['programs'],
    fetchNotes: ['participantId'],
    fetchNotesSuccess: ['notes', 'timeTrackerNotes'],
    fetchProgress: ['participantId', 'date'],
    fetchProgressSuccess: ['progress'],
    setCurrentDate: ['participantId', 'date'],
    setCurrentDateSuccess: ['progressDates'],
    setNotes: ['note'],
    setNotesSuccess: ['addNoteOk'],
    setCurrentProgram: ['currentProgram'],
    setCurrentLevel: ['currentLevel'],
    setPreviousLevel: [],
    setAssignLevel: ['level', 'phase'],
    fetchFiles: ['participantId'],
    fetchFilesSuccess: ['files'],
    setFile: ['participantId', 'file'],
    setParticipantLoading: ['isloadingParticipant'],
    setProgramLoading: ['isloadingProgram'],
    setPrintProgramLoading: ['isloadingPrintProgram'],
    setAssingLevelLoading: ['isloadingAssingLevel'],
    setAssingLevelOk: ['assingLevelOk'],
    setNotesLoading: ['isLoadingNotes'],
    setNotesModalLoading: ['isNotesModalLoading'],
    setFilesLoading: ['isFilesLoading'],
    setProgressLoading: ['isProgressLoading'],
    setParticipantErrors: ['error'],
    setNoteHistorySelected: ['noteHistorySelected'],
    setSelectedCase: ['caseId'],
    setSelectedCaseSuccess: ['caseSelected'],
    setRedirectToError: ['redirectToError'],
    resetParticipant: [],
    setInsuranceDescription: ['insuranceDescription'],
    setInitialEvalDate: ['initialEvalDate'],
    setPlannedRtmEndDate: ['plannedRtmEndDate'],
    setCareNavigatorId: ['careNavigatorId'],
    setExternalCaseId: ['externalId'],
    setExternalCaseIdToCreate: ['externalId'],
    setInsuranceDescriptionCaseToCreate: ['insuranceDescription'],
    setInitialEvalDateCaseToCreate: ['initialEvalDate'],
    setPlannedRtmEndDateCaseToCreate: ['plannedRtmEndDate'],
    setInsuranceCaseToCreate: ['insurance'],
    setProviderCaseToCreate: ['provider'],
    setReportingQualificationsCaseToCreate: ['mips'],
    clearCaseToCreate: [],
  },
  { prefix: '@BE-PARTICIPANT/' }
);

export const ParticipantActions = Creators;
export const ParticipantTypes = Types;

export const INITIAL_STATE = Immutable({
  data: null,
  programs: null,
  progress: null,
  progressDates: {
    dates: [],
    currentDate: moment().format('MM-DD-YYYY'),
  },
  printProgram: null,
  prevLevel: {
    level: null,
    phase: null,
  },
  currentLevel: {
    level: null,
    phase: null,
  },
  currentProgram: null,
  nextSchedule: null,
  notes: {
    notes: [],
    timeTrackerNotes: [],
  },
  cases: [],
  noteHistorySelected: null,
  caseSelected: null,
  caseToCreate: {
    external_id: '',
    insurance_description: '',
    initial_appointment: {
      start: '',
    },
    external_period: {
      planned_rtm_end_date: '',
    },
    insurance: undefined,
    provider: undefined,
    reportingQualification: {},
  },
  files: null,
  isloadingParticipant: false,
  isloadingProgram: false,
  isloadingPrintProgram: false,
  isloadingAssingLevel: false,
  assingLevelOk: null,
  isNotesModalLoading: false,
  isLoadingNotes: false,
  addNoteOk: null,
  isFilesLoading: false,
  isProgressLoading: false,
  isRefreshing: false,
  redirectToError: false,
  errors: [],
});

export const ParticipantSelectors = {
  getParticipant: ({ participant }) => participant.data,
  getParticipantLoading: ({ participant }) => participant.isloadingParticipant,
  getParticipantRefreshing: ({ participant }) => participant.isRefreshing,
  getPrograms: ({ participant }) => participant.programs,
  getCurrentProgram: ({ participant }) => participant.currentProgram,
  getProgramLoading: ({ participant }) => participant.isloadingProgram,
  getProgramPrintLoading: ({ participant }) =>
    participant.isloadingPrintProgram,
  getAssingLevelLoading: ({ participant }) => participant.isloadingAssingLevel,
  getCurrentLevel: ({ participant }) => participant.currentLevel,
  getNonParticipantErrors: ({ participant }) =>
    participant.errors.find((e) => e?.notParticipant),
  getNotes: ({ participant }) => participant.notes,
  getCaseNotes: ({ participant }) => participant.notes.notes,
  getTimeTrackerNotes: ({ participant }) => participant.notes.timeTrackerNotes,
  getNotesLoading: ({ participant }) => participant.isLoadingNotes,
  getAddNoteOk: ({ participant }) => participant.addNoteOk,
  getNotesModalLoading: ({ participant }) => participant.isNotesModalLoading,
  getFiles: ({ participant }) => participant.files,
  getFilesLoading: ({ participant }) => participant.isFilesLoading,
  getProgress: ({ participant }) => participant.progress,
  getProgressLoading: ({ participant }) => participant.isProgressLoading,
  getProgressDates: ({ participant }) => participant.progressDates,
  getAssingLevelOk: ({ participant }) => participant.assingLevelOk,
  getNextSchedule: ({ participant }) => participant.nextSchedule,
  getNoteHistorySelected: ({ participant }) => participant.noteHistorySelected,
  getCaseSelected: ({ participant }) => participant.caseSelected,
  getRedirectToError: ({ participant }) => participant.redirectToError,
  getInsuranceDescription: ({ participant }) =>
    participant.caseSelected?.insurance_description,
  getInitialEvalDate: ({ participant }) =>
    participant.caseSelected?.initial_appointment?.start,
  getPlannedRTMEndDate: ({ participant }) =>
    participant.caseSelected?.external_period?.planned_rtm_end_date,
  getCareNavigatorId: ({ participant }) => participant.caseSelected?.cn?.id,
  getExternalCaseId: ({ participant }) => participant.caseSelected?.external_id,
  getExternalCaseIdToCreate: ({ participant }) =>
    participant.caseToCreate?.external_id,
  getCaseToCreate: ({ participant }) => participant.caseToCreate,
};

/* ------------- REDUCERS ACTIONS ------------------- */

const fetchParticipantSuccess = (state, { data }) => state.merge({ data });

const setRefreshParticipant = (state, { isRefreshing }) =>
  state.merge({ isRefreshing });

const fetchProgramsSuccess = (state, { programs }) => state.merge({ programs });

const fetchProgressSuccess = (state, { progress }) => state.merge({ progress });

const setCurrentLevel = (state, { currentLevel }) =>
  state.merge({
    prevLevel: {
      level: state.currentLevel.level,
      phase: state.currentLevel.phase,
    },
    currentLevel: {
      level: currentLevel?.level,
      phase: currentLevel?.phase,
    },
  });

const setPreviousLevel = (state) =>
  state.merge({
    prevLevel: {
      level: null,
      phase: null,
    },
    currentLevel: {
      ...state.prevLevel,
    },
  });

const setAssingLevelOk = (state, { assingLevelOk }) =>
  state.merge({ assingLevelOk });

const setCurrentProgram = (state, { currentProgram }) =>
  state.merge({ currentProgram });

const setCurrentDateSuccess = (state, { progressDates }) =>
  state.merge({ progressDates });

const fetchNotesSuccess = (state, { notes }) => state.merge({ notes });

const setNotesSuccess = (state, { addNoteOk }) => state.merge({ addNoteOk });

const fetchFilesSuccess = (state, { files }) => state.merge({ files });

const setParticipantLoading = (state, { isloadingParticipant }) =>
  state.merge({ isloadingParticipant });

const setProgramLoading = (state, { isloadingProgram }) =>
  state.merge({ isloadingProgram });

const setPrintProgramLoading = (state, { isloadingPrintProgram }) =>
  state.merge({ isloadingPrintProgram });

const setAssingLevelLoading = (state, { isloadingAssingLevel }) =>
  state.merge({ isloadingAssingLevel });

const setNotesModalLoading = (state, { isNotesModalLoading }) =>
  state.merge({ isNotesModalLoading });

const setNotesLoading = (state, { isLoadingNotes }) =>
  state.merge({ isLoadingNotes });

const setFilesLoading = (state, { isFilesLoading }) =>
  state.merge({ isFilesLoading });

const setProgressLoading = (state, { isProgressLoading }) =>
  state.merge({ isProgressLoading });

const setParticipantErrors = (state, { error }) =>
  state.merge({
    errors: state.errors.concat(error),
  });

const setNoteHistorySelected = (state, { noteHistorySelected }) =>
  state.merge({ noteHistorySelected });

const setSelectedCaseSuccess = (state, { caseSelected }) =>
  state.merge({ caseSelected });

const setRedirectToError = (state, { redirectToError }) =>
  state.merge({ redirectToError });

const resetParticipant = (state) => state.merge({ data: null });

const setInsuranceDescription = (state, { insuranceDescription }) => {
  const newCaseSelected = {
    ...state.caseSelected,
    insurance_description: insuranceDescription,
  };
  const caseIndex = parseInt(state.caseSelected?.id - 1);

  const currentDataCase = state.data === null ? { cases: [] } : state.data;

  const newCases = { ...currentDataCase.cases };
  newCases[caseIndex] = newCaseSelected;
  const newParticipant = { ...currentDataCase, cases: newCases };

  return state.merge({
    caseSelected: newCaseSelected,
    participant: newParticipant,
  });
};

const setInitialEvalDate = (state, { initialEvalDate }) => {
  let currentInitialAppointment = state.caseSelected?.initial_appointment;
  currentInitialAppointment = currentInitialAppointment || {
    start: null,
    end: null,
  };
  const newInitialAppointment = {
    ...currentInitialAppointment,
    start: initialEvalDate,
  };
  const newCaseSelected = {
    ...state.caseSelected,
    initial_appointment: newInitialAppointment,
  };
  const caseIndex = parseInt(state.caseSelected?.id - 1);

  const currentDataCase = state.data === null ? { cases: [] } : state.data;

  const newCases = { ...currentDataCase.cases };
  newCases[caseIndex] = newCaseSelected;
  const newParticipant = { ...currentDataCase, cases: newCases };

  return state.merge({
    caseSelected: newCaseSelected,
    participant: newParticipant,
  });
};

const setPlannedRtmEndDate = (state, { plannedRtmEndDate }) => {
  let currentExternalPeriod = state.caseSelected?.external_period;
  currentExternalPeriod = currentExternalPeriod || {
    planned_rtm_end_date: null,
  };
  const newPlannedExternalPeriod = {
    ...currentExternalPeriod,
    planned_rtm_end_date: plannedRtmEndDate,
  };
  const newCaseSelected = {
    ...state.caseSelected,
    external_period: newPlannedExternalPeriod,
  };
  const caseIndex = parseInt(state.caseSelected?.id - 1);

  const currentDataCase = state.data === null ? { cases: [] } : state.data;

  const newCases = { ...currentDataCase.cases };
  newCases[caseIndex] = newCaseSelected;
  const newParticipant = { ...currentDataCase, cases: newCases };

  return state.merge({
    caseSelected: newCaseSelected,
    participant: newParticipant,
  });
};

const setCareNavigatorId = (state, { careNavigatorId }) => {
  let currentCareNavigator = state.caseSelected?.cn;
  currentCareNavigator = currentCareNavigator || { id: null };
  const newCareNavigator = { ...currentCareNavigator, id: careNavigatorId };
  const newCaseSelected = { ...state.caseSelected, cn: newCareNavigator };
  const caseIndex = parseInt(state.caseSelected?.id - 1);

  const cases = state.data ? { cases: [] } : { ...state.data };

  const newCases = { ...cases };
  newCases[caseIndex] = newCaseSelected;
  const newParticipant = { ...cases, cases: newCases };

  return state.merge({
    caseSelected: newCaseSelected,
    participant: newParticipant,
  });
};

const setExternalCaseId = (state, { externalId }) => {
  const newCaseSelected = { ...state.caseSelected, external_id: externalId };
  const caseIndex = parseInt(state.caseSelected?.id - 1);

  const currentDataCase = state.data === null ? { cases: [] } : state.data;

  const newCases = { ...currentDataCase.cases };
  newCases[caseIndex] = newCaseSelected;
  const newParticipant = { ...currentDataCase, cases: newCases };

  return state.merge({
    caseSelected: newCaseSelected,
    participant: newParticipant,
  });
};

const setExternalCaseIdToCreate = (state, { externalId }) => {
  const newCaseToCreate = { ...state.caseToCreate, external_id: externalId };

  return state.merge({ caseToCreate: newCaseToCreate });
};

const setInsuranceDescriptionCaseToCreate = (
  state,
  { insuranceDescription }
) => {
  const newCaseToCreate = {
    ...state.caseToCreate,
    insurance_description: insuranceDescription,
  };

  return state.merge({ caseToCreate: newCaseToCreate });
};

const setInitialEvalDateCaseToCreate = (state, { initialEvalDate }) => {
  const newCaseToCreate = {
    ...state.caseToCreate,
    initial_appointment: { start: initialEvalDate },
  };

  return state.merge({ caseToCreate: newCaseToCreate });
};

const setPlannedRtmEndDateCaseToCreate = (state, { plannedRtmEndDate }) => {
  const newCaseToCreate = {
    ...state.caseToCreate,
    external_period: { planned_rtm_end_date: plannedRtmEndDate },
  };

  return state.merge({ caseToCreate: newCaseToCreate });
};

const setInsuranceCaseToCreate = (state, { insurance }) => {
  const newCaseToCreate = {
    ...state.caseToCreate,
    insurance: { ...insurance },
  };

  return state.merge({ caseToCreate: newCaseToCreate });
};

const setProviderCaseToCreate = (state, { provider }) => {
  const newCaseToCreate = { ...state.caseToCreate, provider: { ...provider } };

  return state.merge({ caseToCreate: newCaseToCreate });
};

const setReportingQualificationsCaseToCreate = (state, { mips }) => {
  const newCaseToCreate = { ...state.caseToCreate, mips: { ...mips } };

  return state.merge({ caseToCreate: newCaseToCreate });
};

const clearCaseToCreate = (state) => {
  const initialCaseToCreate = {
    external_id: '',
    insurance_description: '',
    initial_appointment: {
      start: '',
    },
    external_period: {
      planned_rtm_end_date: '',
    },
    insurance: {},
    provider: {},
    mips: {},
  };

  return state.merge({ caseToCreate: initialCaseToCreate });
};

export const ParticipantReducer = createReducer(INITIAL_STATE, {
  [Types.FETCH_PARTICIPANT_SUCCESS]: fetchParticipantSuccess,
  [Types.SET_REFRESH_PARTICIPANT]: setRefreshParticipant,
  [Types.FETCH_PROGRAMS_SUCCESS]: fetchProgramsSuccess,
  [Types.FETCH_NOTES_SUCCESS]: fetchNotesSuccess,
  [Types.SET_NOTES_SUCCESS]: setNotesSuccess,
  [Types.FETCH_FILES_SUCCESS]: fetchFilesSuccess,
  [Types.FETCH_PROGRESS_SUCCESS]: fetchProgressSuccess,
  [Types.SET_CURRENT_DATE_SUCCESS]: setCurrentDateSuccess,
  [Types.SET_CURRENT_LEVEL]: setCurrentLevel,
  [Types.SET_PREVIOUS_LEVEL]: setPreviousLevel,
  [Types.SET_CURRENT_PROGRAM]: setCurrentProgram,
  [Types.SET_PARTICIPANT_LOADING]: setParticipantLoading,
  [Types.SET_PROGRAM_LOADING]: setProgramLoading,
  [Types.SET_ASSING_LEVEL_LOADING]: setAssingLevelLoading,
  [Types.SET_PRINT_PROGRAM_LOADING]: setPrintProgramLoading,
  [Types.SET_PARTICIPANT_ERRORS]: setParticipantErrors,
  [Types.SET_NOTES_LOADING]: setNotesLoading,
  [Types.SET_NOTES_MODAL_LOADING]: setNotesModalLoading,
  [Types.SET_FILES_LOADING]: setFilesLoading,
  [Types.SET_PROGRESS_LOADING]: setProgressLoading,
  [Types.SET_ASSING_LEVEL_OK]: setAssingLevelOk,
  [Types.SET_NOTE_HISTORY_SELECTED]: setNoteHistorySelected,
  [Types.SET_SELECTED_CASE_SUCCESS]: setSelectedCaseSuccess,
  [Types.RESET_PARTICIPANT]: resetParticipant,
  [Types.SET_REDIRECT_TO_ERROR]: setRedirectToError,
  [Types.SET_INSURANCE_DESCRIPTION]: setInsuranceDescription,
  [Types.SET_INITIAL_EVAL_DATE]: setInitialEvalDate,
  [Types.SET_PLANNED_RTM_END_DATE]: setPlannedRtmEndDate,
  [Types.SET_CARE_NAVIGATOR_ID]: setCareNavigatorId,
  [Types.SET_EXTERNAL_CASE_ID]: setExternalCaseId,
  [Types.SET_EXTERNAL_CASE_ID_TO_CREATE]: setExternalCaseIdToCreate,
  [Types.SET_INSURANCE_DESCRIPTION_CASE_TO_CREATE]:
    setInsuranceDescriptionCaseToCreate,
  [Types.SET_INITIAL_EVAL_DATE_CASE_TO_CREATE]: setInitialEvalDateCaseToCreate,
  [Types.SET_PLANNED_RTM_END_DATE_CASE_TO_CREATE]:
    setPlannedRtmEndDateCaseToCreate,
  [Types.SET_INSURANCE_CASE_TO_CREATE]: setInsuranceCaseToCreate,
  [Types.SET_PROVIDER_CASE_TO_CREATE]: setProviderCaseToCreate,
  [Types.SET_REPORTING_QUALIFICATIONS_CASE_TO_CREATE]:
    setReportingQualificationsCaseToCreate,
  [Types.CLEAR_CASE_TO_CREATE]: clearCaseToCreate,
});
