import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions(
  {
    fetchHepTemplates: ['search'],
    fetchHepTemplatesSuccess: ['templates'],
    setHepTemplatesIsLoading: ['hepTemplatesIsLoading'],
    fetchProgramPrint: [
      'participantId',
      'layout',
      'mode',
      'participantLastName',
      'programName',
      'caseId',
      'isLoginRestricted',
    ],
    fetchProgramPrintSuccess: ['pdfUrl'],
    resetProgramPrint: [],
    setProgramPrintIsLoading: ['programPrintIsLoading'],
    setExerciseToBuilderData: ['exercise'],
    removeExerciseToBuilderData: ['exerciseId'],
    moveExerciseToBuilderData: ['exerciseId', 'direction'],
    editExerciseOnBuilderData: ['exerciseId', 'newValues'],
    addDefaultValuesOnBuilderData: ['values', 'fromIndex'],
    setBuilderData: ['builderData'],
    createHep: ['data'],
    createHepSuccess: ['newHepAction'],
    resetCreateHep: [],
    setCreateHepIsLoading: ['isCreateHepLoading'],
    getHepToEdit: { programId: null, templateId: null, editable: false },
    getHepToEditSuccess: ['programToEdit'],
    setHepToEditIsLoading: ['isLoadingHepToEdit'],
    resetHepToEdit: [],
    setErrors: ['errors'],
    resetHep: [],
    print: ['print'],
    notify: ['notify'],
    notifyAndPrint: ['notifyAndPrint'],
  },
  { prefix: '@BE-HEP/' }
);

export const HepActions = Creators;
export const HepTypes = Types;

export const HEP_INITIAL_STATE = Immutable({
  templates: null,
  hepTemplatesIsLoading: false,
  programPrintIsLoading: false,
  pdfUrl: null,
  builderData: [],
  newHepAction: null,
  isCreateHepLoading: false,
  isLoadingHepToEdit: false,
  programToEdit: null,
  errors: [],
});

export const HepSelectors = {
  getHepTemplates: ({ hep }) => hep.templates,
  getHepTemplatesIsLoading: ({ hep }) => hep.hepTemplatesIsLoading,
  getEditCreateCaseModalValue: ({ hep }) => hep.editCreateCaseModalValue,
  getProgramPrint: ({ hep }) => hep.pdfUrl,
  getProgramPrintIsLoading: ({ hep }) => hep.programPrintIsLoading,
  getProgramPrintErrors: ({ hep }) =>
    hep.errors?.find((error) => error?.name === 'PRINT_ERROR'),
  getBuilderData: ({ hep }) =>
    Immutable.asMutable(hep.builderData, { deep: true }),
  getCreateHepIsLoading: ({ hep }) => hep.isCreateHepLoading,
  getHepAction: ({ hep }) => hep.newHepAction,
  getHepToEditIsLoading: ({ hep }) => hep.isLoadingHepToEdit,
  getHepToEdit: ({ hep }) => hep.programToEdit,
};

/* ------------- REDUCERS ACTIONS ------------------- */

const fetchHepTemplatesSuccess = (state, { templates }) =>
  state.merge({ templates });

const setHepTemplatesIsLoading = (state, { hepTemplatesIsLoading }) =>
  state.merge({ hepTemplatesIsLoading });

const fetchProgramPrintSuccess = (state, { pdfUrl }) => state.merge({ pdfUrl });

const resetProgramPrint = (state) => state.merge({ pdfUrl: null });

const setProgramPrintIsLoading = (state, { programPrintIsLoading }) =>
  state.merge({ programPrintIsLoading });

const setExerciseToBuilderData = (state, { exercise }) => {
  const builderData = state.builderData.concat([exercise]);

  return state.merge({ builderData });
};

const removeExerciseToBuilderData = (state, { exerciseId }) => {
  if (state.builderData.length === 1) {
    return state.merge({
      ...state,
      builderData: HEP_INITIAL_STATE.builderData,
    });
  }

  const builderData = state.builderData.filter(({ id }) => id !== exerciseId);
  return state.merge({ builderData });
};

const moveExerciseToBuilderData = (state, { exerciseId, direction }) => {
  const builderData = Immutable.asMutable(state.builderData);
  if (builderData.length === 1) {
    return state.merge({
      ...state,
      builderData: state.builderData,
    });
  }

  const index = builderData.findIndex(({ id }) => id === exerciseId);
  const exercise = builderData.find(({ id }) => id === exerciseId);
  let destination = index - 1 < 0 ? state.builderData.length - 1 : index - 1;

  if (direction === 'down') {
    destination = index + 1 > state.builderData.length ? 0 : index + 1;
  }

  const destinationItem = builderData[destination];
  builderData[index] = destinationItem;
  builderData[destination] = exercise;

  return state.merge({ builderData });
};

const editExerciseOnBuilderData = (state, { exerciseId, newValues }) => {
  const builderData = Immutable.asMutable(state.builderData, { deep: true });
  const exerciseIndex = builderData.findIndex(({ id }) => id === exerciseId);
  const exercise = builderData.find(({ id }) => id === exerciseId);

  builderData[exerciseIndex] = { ...exercise, ...newValues };

  return state.merge({ builderData });
};

const addDefaultValuesOnBuilderData = (state, { values, fromIndex }) => {
  const builderData = Immutable.asMutable(state.builderData, { deep: true });

  for (let index = fromIndex; index < builderData.length; index += 1) {
    // NOTE: this is legacy functionality that will be rewritten.
    // don't copy note from above data
    const indexNote = builderData[index]?.note;
    builderData[index] = { ...builderData[index], ...values, note: indexNote };
  }

  return state.merge({ builderData });
};

const setBuilderData = (state, { builderData }) => state.merge({ builderData });

const createHepSuccess = (state, { newHepAction }) =>
  state.merge({ newHepAction });

const resetCreateHep = (state) => state.merge({ newHepAction: null });

const setCreateHepIsLoading = (state, { isCreateHepLoading }) =>
  state.merge({ isCreateHepLoading });

const setHepToEditIsLoading = (state, { isLoadingHepToEdit }) =>
  state.merge({ isLoadingHepToEdit });

const getHepToEditSuccess = (state, { programToEdit }) =>
  state.merge({ programToEdit });

const resetHepToEdit = (state) => state.merge({ programToEdit: null });

const setErrors = (state, { errors }) => state.merge({ errors });

const resetHep = (state) => state.merge(HEP_INITIAL_STATE);

export const HepReducer = createReducer(HEP_INITIAL_STATE, {
  [Types.FETCH_HEP_TEMPLATES_SUCCESS]: fetchHepTemplatesSuccess,
  [Types.SET_HEP_TEMPLATES_IS_LOADING]: setHepTemplatesIsLoading,
  [Types.FETCH_PROGRAM_PRINT_SUCCESS]: fetchProgramPrintSuccess,
  [Types.RESET_PROGRAM_PRINT]: resetProgramPrint,
  [Types.SET_PROGRAM_PRINT_IS_LOADING]: setProgramPrintIsLoading,
  [Types.SET_EXERCISE_TO_BUILDER_DATA]: setExerciseToBuilderData,
  [Types.REMOVE_EXERCISE_TO_BUILDER_DATA]: removeExerciseToBuilderData,
  [Types.MOVE_EXERCISE_TO_BUILDER_DATA]: moveExerciseToBuilderData,
  [Types.SET_CREATE_HEP_IS_LOADING]: setCreateHepIsLoading,
  [Types.EDIT_EXERCISE_ON_BUILDER_DATA]: editExerciseOnBuilderData,
  [Types.ADD_DEFAULT_VALUES_ON_BUILDER_DATA]: addDefaultValuesOnBuilderData,
  [Types.SET_BUILDER_DATA]: setBuilderData,
  [Types.CREATE_HEP_SUCCESS]: createHepSuccess,
  [Types.RESET_CREATE_HEP]: resetCreateHep,
  [Types.SET_HEP_TO_EDIT_IS_LOADING]: setHepToEditIsLoading,
  [Types.GET_HEP_TO_EDIT_SUCCESS]: getHepToEditSuccess,
  [Types.RESET_HEP_TO_EDIT]: resetHepToEdit,
  [Types.SET_ERRORS]: setErrors,
  [Types.RESET_HEP]: resetHep,
});
