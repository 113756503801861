export const getSelectedElementsOnList = (list, selectedList) => {
  return list.filter((listElem) =>
    selectedList.find((selected) => selected.value === listElem.value)
  );
};

export const flatSelectedElementsToIds = (list) => {
  if (!list || list.length === 0) {
    return null;
  }

  if (!Array.isArray(list)) {
    return [list.value];
  }

  return list.map((listElem) => listElem.value);
};

// TODO: remove this when no list contains single elements.
export const removeAllSelectedElements = (list) => {
  const hasAll = list.filter((listElem) => listElem.value === 0);
  let newList = list;

  if (hasAll.length > 0) {
    newList = list.filter((elem) => elem.value !== 0);
  }

  return newList;
};
