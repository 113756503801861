import { put, call } from 'redux-saga/effects';
import featureFlagsService from 'services/api/featureFlagsService';
import {
  FeatureFlagsActions as Actions,
  FeatureFlagsTypes as Types,
} from './featureFlagsReducer';

function* fetchFeatureFlags() {
  try {
    const response = yield call(featureFlagsService);

    yield put(Actions.fetchFeatureFlagsSuccess(response));
  } catch (error) {
    yield put(
      Actions.setFeatureFlagsErrors({
        error,
        message: '[getFeatureFlags]: problem getting data',
        unauthorized: true,
      })
    );
  }
}

export default () => ({
  [Types.FETCH_FEATURE_FLAGS]: fetchFeatureFlags,
});
