import { debounce, throttle } from 'redux-saga/effects';

export const delay = (time) =>
  new Promise((resolve) => setTimeout(resolve, time));

const getSagaByType = (type, sagasProvider, ...args) => [
  type,
  sagasProvider()[type],
  ...args,
];

export const getSagasBinds = ({ data, sagas }) =>
  data.map(({ type, action, time = 1000 }) => {
    return throttle === action || debounce === action
      ? action(time, ...getSagaByType(type, sagas))
      : action(...getSagaByType(type, sagas));
  });
