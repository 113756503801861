import { put, call, select } from 'redux-saga/effects';
import { saveTimetracker as saveTimetrackerApi } from 'services/api/participant';
import { ParticipantActions, ParticipantSelectors } from 'store/participant';
import Swal from 'sweetalert2';
import {
  RtmSelectors as Selectors,
  RtmActions as Actions,
  RtmTypes as Types,
} from './rtmReducer';

function* saveTimeTracking(action) {
  yield put(Actions.setSaveTimeTrackingLoading(true));
  const currentParticipantId = yield select(Selectors.getCurrentParticipantId);
  const currentCaseId = yield select(Selectors.getCurrentCaseId);
  const participant = yield select(ParticipantSelectors.getParticipant);

  const { time, note, noteType, startDate } = action;

  try {
    yield call(saveTimetrackerApi, {
      user_id: currentParticipantId,
      start_date: startDate,
      time,
      note,
      type_id: Number(noteType),
      case_id: currentCaseId,
    });

    Swal.fire('Note', `The note was added successfully`, 'success');

    if (participant?.id === currentParticipantId) {
      yield put(ParticipantActions.fetchNotes(currentParticipantId));
    }
  } catch (error) {
    Swal.fire(
      'Oops!',
      `Limber wasn't able to add the note, please try again`,
      'error'
    );
    yield put(
      Actions.setRtmErrors({
        error,
        message: '[saveTimetracker Error] problem getting data',
      })
    );
  } finally {
    yield put(Actions.setSaveTimeTrackingLoading(false));
  }
}

export default () => ({
  [Types.SAVE_TIME_TRACKING]: saveTimeTracking,
});
