import { getRequest, postRequest, deleteRequest } from './base';

export const changeUserPassword = (id, password) => {
  return postRequest('user/setPassword', {
    id,
    password,
  }).then((response) => response.data);
};

export const getUserFiltersView = async (section) => {
  const response = await getRequest(`/filters/list?section=${section}`);

  return response.data;
};

export const createUserFiltersView = async (section, filters, name) => {
  const filterWithValues = Object.fromEntries(
    Object.entries(filters).filter(([, value]) => value)
  );
  const response = await postRequest(`/filters`, {
    section,
    filters: filterWithValues,
    name,
  });

  return response.data;
};

export const deleteUserFiltersView = async (id) => {
  const response = await deleteRequest(`/filters/delete/${id}`);

  return response.data;
};

export const fetchUserTenants = async () => {
  const response = await getRequest('/users/tenants');
  // TODO: Sort these once we can ensure the initially selected tenant is the "default" tenant set in the Auth0 user_metadata
  // const sortedTenants = response.data.sort(
  //   ({ name: item1Name }, { name: item2Name }) =>
  //     item1Name.localeCompare(item2Name)
  // );
  // return sortedTenants;
  return response.data;
};
