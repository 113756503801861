import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions(
  {
    setCurrentParticipantId: ['currentParticipantId'],
    setCurrentCaseId: ['currentCaseId'],
    setTimeTrackingIsRunning: ['timeTrackingIsRunning'],
    saveTimeTracking: ['time', 'note', 'noteType', 'startDate'],
    setSaveTimeTrackingLoading: ['isLoadingSaveTimeTracking'],
    setRtmErrors: ['error'],
  },
  { prefix: '@BE-RTM/' }
);

export const RtmActions = Creators;
export const RtmTypes = Types;

export const INITIAL_STATE = Immutable({
  currentParticipantId: null,
  currentCaseId: null,
  timeTrackingIsRunning: false,
  isLoadingSaveTimeTracking: false,
  errors: [],
});

export const RtmSelectors = {
  getCurrentParticipantId: ({ rtm }) => rtm.currentParticipantId,
  getCurrentCaseId: ({ rtm }) => rtm.currentCaseId,
  getTimeTrackingIsRunning: ({ rtm }) => rtm.timeTrackingIsRunning,
  getSaveTimeTrackingLoading: ({ rtm }) => rtm.isLoadingSaveTimeTracking,
};

/* ------------- REDUCERS ACTIONS ------------------- */

const setCurrentParticipantId = (state, { currentParticipantId }) =>
  state.merge({ currentParticipantId });

const setCurrentCaseId = (state, { currentCaseId }) =>
  state.merge({ currentCaseId });

const setTimeTrackingIsRunning = (state, { timeTrackingIsRunning }) =>
  state.merge({ timeTrackingIsRunning });

const setSaveTimeTrackingLoading = (state, { isLoadingSaveTimeTracking }) =>
  state.merge({ isLoadingSaveTimeTracking });

const setRtmErrors = (state, { error }) =>
  state.merge({
    errors: state.errors.concat(error),
  });

export const RtmReducer = createReducer(INITIAL_STATE, {
  [Types.SET_CURRENT_PARTICIPANT_ID]: setCurrentParticipantId,
  [Types.SET_CURRENT_CASE_ID]: setCurrentCaseId,
  [Types.SET_TIME_TRACKING_IS_RUNNING]: setTimeTrackingIsRunning,
  [Types.SET_SAVE_TIME_TRACKING_LOADING]: setSaveTimeTrackingLoading,
  [Types.SET_RTM_ERRORS]: setRtmErrors,
});
