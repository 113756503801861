import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions(
  {
    setFavoriteEducation: ['education'],
    setFavoriteEducationSuccess: ['education'],
    fetchEducations: ['filters'],
    fetchEducationsSuccess: ['educationsPaginated'],
    setEducationsIsLoading: ['isEducationsLoading'],
    setEducationErrors: ['error'],
  },
  { prefix: '@BE-EDUCATION/' }
);

export const EducationsActions = Creators;
export const EducationsTypes = Types;

export const INITIAL_STATE = Immutable({
  educationsPaginated: {},
  isEducationsLoading: false,
  errors: [],
});

export const EducationsSelectors = {
  getEducationsPaginated: ({ educations }) => educations.educationsPaginated,
  getEducationsLoading: ({ educations }) => educations.isEducationsLoading,
  getCriticalEducationErros: ({ educations }) =>
    educations.errors.find((error) => error?.unauthorized),
};

/* ------------- REDUCERS ACTIONS ------------------- */

const fetchEducationsSuccess = (state, { educationsPaginated }) =>
  state.merge({ educationsPaginated });
const setEducationsIsLoading = (state, { isEducationsLoading }) =>
  state.merge({ isEducationsLoading });

const setEducationErrors = (state, { error }) =>
  state.merge({
    errors: state.errors.concat(error),
  });

const setFavoriteEducation = (state, { education }) => state.merge({ education });

const setFavoriteEducationSuccess = (state, { education }) => {
  let educationsPaginated = {...state.educationsPaginated};
  let educationFavorite = education.platformEducation;
  let rows = educationsPaginated.rows.map(education => education.id === educationFavorite.id ? { ...education, ...educationFavorite } : education);
  educationsPaginated.rows = rows;
  return state.merge({ educationsPaginated });
};

export const EducationsReducer = createReducer(INITIAL_STATE, {
  [Types.FETCH_EDUCATIONS_SUCCESS]: fetchEducationsSuccess,
  [Types.SET_EDUCATION_ERRORS]: setEducationErrors,
  [Types.SET_FAVORITE_EDUCATION]: setFavoriteEducation,
  [Types.SET_EDUCATIONS_IS_LOADING]: setEducationsIsLoading,
  [Types.SET_FAVORITE_EDUCATION_SUCCESS]: setFavoriteEducationSuccess
});
