import { CONTENT_TYPES } from 'constants/domo';
import { postRequest } from './base';

const getDomoUrl = async (id, elementType = CONTENT_TYPES.DASHBOARD) => {
  const response = await postRequest(`/domo/createToken`, {
    id,
    elementType,
  });

  return response.data;
};

export default getDomoUrl;
