import { takeLatest } from 'redux-saga/effects';
import { getSagasBinds } from 'helpers/sagasHelper';
import { ProtocolsTypes as Types } from './protocolsReducer';
import ProtocolsSagas from './protocolsSagas';

const protocolsTemplate = {
  data: [
    {
      type: Types.FETCH_PROTOCOLS,
      action: takeLatest,
    },
    {
      type: Types.FETCH_FILTERS,
      action: takeLatest,
    },
    {
      type: Types.SET_SELECTED_FILTERS,
      action: takeLatest,
    },
  ],
  sagas: ProtocolsSagas,
};

// eslint-disable-next-line import/prefer-default-export
export const ProtocolsBinds = getSagasBinds(protocolsTemplate);
