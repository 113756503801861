import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions(
    {
        setEditCreateCaseModalValue: ['editCreateCaseModalValue'],
        setIsParticipantRestricted: ['isParticipantRestricted'],
        setIsPrintOpen: ['isPrintOpen'],
        setCaseOption: ['caseOption'],
        setParticipant: ['participant'],
        setServiceType: ['serviceType'],
        setIsServiceTypeVisible: ['isServiceTypeVisible'],
        setIsAcepter: ['isAcepter'],
        setShouldPrint: ['shouldPrint'],
        addNewCase: ['case'],
        resetDrawer: [],
    },
    { prefix: '@FE-HEP/' },
);

export const HepDrawerActions = Creators;
export const HepDrawerTypes = Types;

export const INITIAL_STATE = Immutable({
    editCreateCaseModalValue: null,
    isParticipantRestricted: false,
    isPrintOpen: false,
    caseOption: null,
    participant: null,
    serviceType: null,
    isServiceTypeVisible: false,
    isAcepter: false,
    shouldPrint: false,
});

export const HepDrawerSelectors = {
    getEditCreateCaseModalValue: ({ drawer }) => drawer.editCreateCaseModalValue,
    getIsParticipantRestricted: ({ drawer }) => drawer.isParticipantRestricted,
    getIsPrintOpen: ({ drawer }) => drawer.isPrintOpen,
    getCaseOption: ({ drawer }) => drawer.caseOption,
    getParticipant: ({ drawer }) => drawer.participant,
    getServiceType: ({ drawer }) => drawer.serviceType,
    getIsServiceTypeVisible: ({ drawer }) => drawer.isServiceTypeVisible,
    getIsAcepter: ({ drawer }) => drawer.isAcepter,
    getShouldPrint: ({ drawer }) => drawer.shouldPrint,
};

/* ------------- REDUCERS ACTIONS ------------------- */

const setEditCreateCaseModalValue = (state, { editCreateCaseModalValue }) => state.merge({ editCreateCaseModalValue });
const setIsParticipantRestricted = (state, { isParticipantRestricted }) => state.merge({ isParticipantRestricted });
const setIsPrintOpen = (state, { isPrintOpen }) => state.merge({ isPrintOpen });
const setCaseOption = (state, { caseOption }) => state.merge({ caseOption });
const setParticipant = (state, { participant }) => state.merge({ participant });
const setServiceType = (state, { serviceType }) => state.merge({ serviceType });
const setIsServiceTypeVisible = (state, { isServiceTypeVisible }) => state.merge({ isServiceTypeVisible });
const setIsAcepter = (state, { isAcepter }) => state.merge({ isAcepter });
const setShouldPrint = (state, { shouldPrint }) => state.merge({ shouldPrint });
const addNewCase = (state, options) => {    
    const newCases = [...state.participant.cases, options.case];
    const newParticipant = { ...state.participant, cases: newCases };
    return state.merge({ participant: newParticipant })
};
const resetDrawer = (state) => state.merge({
    ...INITIAL_STATE,
    isPrintOpen: state.isPrintOpen,
    shouldPrint: state.shouldPrint
});

export const DrawerReducer = createReducer(INITIAL_STATE, {
    [Types.SET_EDIT_CREATE_CASE_MODAL_VALUE]: setEditCreateCaseModalValue,
    [Types.SET_IS_PARTICIPANT_RESTRICTED]: setIsParticipantRestricted,
    [Types.SET_IS_PRINT_OPEN]: setIsPrintOpen,
    [Types.SET_CASE_OPTION]: setCaseOption,
    [Types.SET_PARTICIPANT]: setParticipant,
    [Types.SET_SERVICE_TYPE]: setServiceType,
    [Types.SET_IS_SERVICE_TYPE_VISIBLE]: setIsServiceTypeVisible,
    [Types.SET_IS_ACEPTER]: setIsAcepter,
    [Types.SET_SHOULD_PRINT]: setShouldPrint,
    [Types.ADD_NEW_CASE]: addNewCase,
    [Types.RESET_DRAWER]: resetDrawer,
});
