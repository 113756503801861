import { getSagasBinds } from 'helpers/sagasHelper';
import { takeLatest } from 'redux-saga/effects';
import RtmSagas from './rtmSagas';
import { RtmTypes as Types } from './rtmReducer';

const rtmTemplate = {
  data: [
    {
      type: Types.SAVE_TIME_TRACKING,
      action: takeLatest,
    },
  ],
  sagas: RtmSagas,
};

// eslint-disable-next-line import/prefer-default-export
export const RtmBinds = getSagasBinds(rtmTemplate);
