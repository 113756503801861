import { getSagasBinds } from 'helpers/sagasHelper';
import { takeLatest } from 'redux-saga/effects';
import EducationSagas from './educationsSagas';
import { EducationsTypes as Types } from './educationsReducer';

const educationTemplate = {
  data: [
    {
      type: Types.FETCH_EDUCATIONS,
      action: takeLatest,
    },
    {
      type: Types.SET_FAVORITE_EDUCATION,
      action: takeLatest,
    }
  ],
  sagas: EducationSagas,
};

// eslint-disable-next-line import/prefer-default-export
export const educationsBinds = getSagasBinds(educationTemplate);
