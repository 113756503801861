import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import protocolsKeys from 'constants/protocolsFilters';

const { Types, Creators } = createActions(
  {
    fetchProtocols: [],
    fetchProtocolsSuccess: ['protocols'],
    fetchFilters: [],
    fetchFiltersSuccess: ['filters'],
    setProtocolsIsLoading: ['protocolsIsLoading'],
    setFiltersIsLoading: ['isloadingFilters'],
    setSelectedFilters: ['filter'],
    setSelectedFiltersSuccess: ['selectedFilters'],
    setSearch: ['search'],
    resetProtocols: [],
  },
  { prefix: '@BE-PROTOCOLS/' }
);

export const ProtocolsActions = Creators;
export const ProtocolsTypes = Types;

export const INITIAL_STATE = Immutable({
  protocols: null,
  protocolsIsLoading: false,
  filters: {
    [protocolsKeys.category]: null,
    [protocolsKeys.region]: null,
  },
  selectedFilters: {
    [protocolsKeys.category]: null,
    [protocolsKeys.region]: null,
  },
  isloadingFilters: false,
  search: '',
  errors: [],
});

export const ProtocolsSelectors = {
  getProtocols: ({ protocols }) => protocols.protocols,
  getProtocolsIsLoading: ({ protocols }) => protocols.protocolsIsLoading,
  getFiltersIsLoading: ({ protocols }) => protocols.isloadingFilters,
  getFilters: ({ protocols }) => protocols.filters,
  getSelectedFilters: ({ protocols }) => protocols.selectedFilters,
  getSearch: ({ protocols }) => protocols.search,
};

/* ------------- REDUCERS ACTIONS ------------------- */

const fetchProtocolsSuccess = (state, { protocols }) =>
  state.merge({ protocols });

const fetchFiltersSuccess = (state, { filters }) =>
  state.merge({ filters: { ...state.filters, ...filters } });

const setSelectedFiltersSuccess = (state, { selectedFilters }) =>
  state.merge({
    selectedFilters: { ...state.selectedFilters, ...selectedFilters },
  });

const setProtocolsIsLoading = (state, { protocolsIsLoading }) =>
  state.merge({ protocolsIsLoading });

const setFiltersIsLoading = (state, { isloadingFilters }) =>
  state.merge({ isloadingFilters });

const setSearch = (state, { search }) => state.merge({ search });

const resetProtocols = (state) => state.merge(INITIAL_STATE);

export const ProtocolsReducer = createReducer(INITIAL_STATE, {
  [Types.FETCH_PROTOCOLS_SUCCESS]: fetchProtocolsSuccess,
  [Types.FETCH_FILTERS_SUCCESS]: fetchFiltersSuccess,
  [Types.SET_SELECTED_FILTERS_SUCCESS]: setSelectedFiltersSuccess,
  [Types.SET_PROTOCOLS_IS_LOADING]: setProtocolsIsLoading,
  [Types.SET_FILTERS_IS_LOADING]: setFiltersIsLoading,
  [Types.SET_SEARCH]: setSearch,
  [Types.RESET_PROTOCOLS]: resetProtocols,
});
