import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions(
  {
    fetchFeatureFlags: [],
    fetchFeatureFlagsSuccess: ['features'],
    setFeatureFlagsErrors: ['error'],
  },
  { prefix: '@BE-FEATURES/' }
);

export const FeatureFlagsActions = Creators;
export const FeatureFlagsTypes = Types;

export const INITIAL_STATE = Immutable({
  features: {
    PROVIDER_DASHBOARD: false,
    PROVIDER_EDUCATIONS: false,
    IS_LOGIN_RESTRICTED: 0,
    LOGIN_RESTRICTION_REASON: null,
    IS_OUTCOMES_ENABLED: false,
  },
  errors: [],
});

export const FeatureFlagsSelectors = {
  getFeatureFlags: ({ featureFlags }) => featureFlags.features,
};

/* ------------- REDUCERS ACTIONS ------------------- */
const fetchFeatureFlagsSuccess = (state, { features }) =>
  state.merge({ features });

const setFeatureFlagsErrors = (state, { error }) =>
  state.merge({
    errors: state.errors.concat(error),
  });

export const featureFlagsReducer = createReducer(INITIAL_STATE, {
  [Types.FETCH_FEATURE_FLAGS_SUCCESS]: fetchFeatureFlagsSuccess,
  [Types.SET_FEATURE_FLAGS_ERRORS]: setFeatureFlagsErrors,
});
