import { caseStatus as caseStatuses } from 'constants/case';
import { truncate } from 'lodash';
import moment from 'moment';
import { getServiceTypesAvailableToTenant, serviceTypeIDMap } from 'services/api/generic';

export const getLatestPractioner = (practioners) => {
  if (practioners) {
    let lastestStartDate = new Date(Math.max(...practioners.map(practioner => new Date(practioner.start_date))));
    var mostRecentObject = practioners.find(practioner => {
      var startDate = new Date(practioner.start_date);
      return startDate.getTime() == lastestStartDate.getTime();
    });
    return mostRecentObject;
  }
}

export const checkIfAnotherCaseIsRTMCategoryType = (participant, selectedCaseID) => {
  return participant?.cases?.filter(
    (c) => Number(c.id) !== Number(selectedCaseID)).filter(
      (c) => c.status === caseStatuses.ACTIVE || c.status === caseStatuses.PLANNED).some(
        (c) => parseInt(c.service_type) === serviceTypeIDMap().RTM
      );
}

export const checkIfCaseDataIsActiveOrPlanned = (caseData) => {
  return (caseData.serviceType?.value === serviceTypeIDMap().RTM) &&
    (caseData.caseStatus?.value === caseStatuses.ACTIVE ||
      caseData.caseStatus?.value === caseStatuses.PLANNED ||
      caseData.caseStatus === '')
}

const formatProvider = (caseData, providersList) => {
  if (caseData.medic) {
    const firstInitial = caseData.medic.first_name.charAt(0).toUpperCase();
    return `${firstInitial} ${caseData.medic.last_name}`;
  } else {
    const provider = getLatestPractioner(caseData.practitioners);
    const providerDescription =
      providersList.find((f) => f.value === Number(provider?.id))
        ?.label ?? '';
    const firstInitial = providerDescription.charAt(0).toUpperCase();
    return `${firstInitial} ${providerDescription.substring(providerDescription.indexOf(" ") + 1)}`;
  }
}

export const formatCaseLabel = (
  caseData,
  providersList,
  displayServiceType = false
) => {
  if (
    !caseData ||
    !providersList ||
    typeof providersList?.length === 'undefined' ||
    providersList?.length <= 0
  )
    return '';

  const periodDate =
    caseData.period === undefined ? { startDate: '' } : caseData.period;
  const date = moment(new Date(periodDate.start_date)).format('MM/DD/YYYY');
  const regionOfInjuryLabel = caseData.region_of_injury
    ? `${caseData.region_of_injury}`
    : '';
  const serviceType = getServiceTypesAvailableToTenant().find(
    ({ id }) => id === caseData.service_type
  );
  const serviceTypeLabel =
    displayServiceType && serviceType?.name
      ? serviceType?.name
      : 'No Service Type';
  const suffixHyphen = regionOfInjuryLabel || serviceTypeLabel ? '- ' : '';
  const regionServiceTypeSplitter =
    regionOfInjuryLabel && serviceTypeLabel ? ', ' : '';

  const caseDisplayName = caseData.external_name
    ? caseData.external_name
    : caseData.name;

  return `${truncate(caseDisplayName, {
    length: 38,
  })} - ${formatProvider(
    caseData,
    providersList
  )} - ${date} ${suffixHyphen}${regionOfInjuryLabel}${regionServiceTypeSplitter}${serviceTypeLabel}`;
};

/**
 * Returns case index to pre select at first load following bussiness rule.
 *
 * @param {Array} cases participant cases array.
 * @return {number} case index.
 */
export const getPreSelectedCaseByBussinessRule = (cases) => {
  const participantCasesByDate = [...cases ?? []].sort((a, b) => b.id - a.id);
  const lastActiveCase = participantCasesByDate.findIndex(_case => _case.status === caseStatuses.ACTIVE);
  const lastOnHoldCase = participantCasesByDate.findIndex(_case => _case.status === caseStatuses.ONHOLD);
  const lastFinishedCase = participantCasesByDate.findIndex(_case => _case.status === caseStatuses.FINISHED);
  const lastPlannedCase = participantCasesByDate.findIndex(_case => _case.status === caseStatuses.PLANNED);
  const sortingOrder = [lastActiveCase, lastOnHoldCase, lastFinishedCase, lastPlannedCase];
  const lastCaseRule = sortingOrder.findIndex(i => i >= 0);
  return participantCasesByDate[lastCaseRule >= 0 ? sortingOrder[lastCaseRule] : 0];
}

/**
 * Returns cases ordered by the bussiness rule: 
 *
 * Active, On Hold, Finished and Planned cases ordered by date
 * 
 * @param {Array} cases participant cases array.
 * @return {Array} ordered cases.
 */
export const getCasesOrderedByBussinessRule = (cases) => {
  const statusPriority = {
    [caseStatuses.ACTIVE]: 1,
    [caseStatuses.ONHOLD]: 2,
    [caseStatuses.FINISHED]: 3,
    [caseStatuses.PLANNED]: 4,
  };

  return [...cases ?? []]
    .sort((a, b) => b.id - a.id)
    .sort((case_a, case_b) => statusPriority[case_a.status] - statusPriority[case_b.status]);
}