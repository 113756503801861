import { put, call, select } from 'redux-saga/effects';
import { OUTCOMES_FEATURE_FLAGS } from 'constants/outcomes';
import { getOutcomesFeatureFlags } from 'services/api/outcomes';
import { getSelectedTenantId } from 'helpers/outcomesHelpers';
// eslint-disable-next-line import/no-cycle
import { UserSelectors } from 'store/user';
import {
  OutcomesFeatureFlagsActions as Actions,
  OutcomesFeatureFlagsTypes as Types,
} from './featureFlagsReducer';

function* fetchOutcomesFeatureFlags() {
  try {
    const currentTenant = yield select(UserSelectors.getCurrentTenant);
    const tenantList = yield select(UserSelectors.getTenantList);
    const tenantId = getSelectedTenantId(currentTenant, tenantList);
    if (!tenantId) {
      return;
    }
    const response = yield call(getOutcomesFeatureFlags, { tenantId });
    const features = Object.entries(OUTCOMES_FEATURE_FLAGS).reduce(
      (acc, [key, value]) => {
        return { ...acc, [key]: response[value] };
      },
      {}
    );
    yield put(Actions.fetchOutcomesFeatureFlagsSuccess(features));
  } catch (error) {
    yield put(
      Actions.setOutcomesFeatureFlagsErrors({
        error,
        message: '[getOutcomesFeatureFlags]: problem getting data',
        unauthorized: true,
      })
    );
  }
}

export default () => ({
  [Types.FETCH_OUTCOMES_FEATURE_FLAGS]: fetchOutcomesFeatureFlags,
});
