import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions(
  {
    fetchChatUrl: ['participantId'],
    fetchChatUrlSuccess: ['chatUrl'],
    setChatUrlLoading: ['isloadingChatUrl'],
    setProviderErrors: ['error'],
  },
  { prefix: '@BE-PROVIDER/' }
);

export const ProviderActions = Creators;
export const ProviderTypes = Types;

export const INITIAL_STATE = Immutable({
  chatUrl: null,
  isloadingChatUrl: false,
  errors: [],
});

export const ProviderSelectors = {
  getChatUrl: ({ provider }) => provider.chatUrl,
  getChatUrlLoading: ({ provider }) => provider.isloadingChatUrl,
};

/* ------------- REDUCERS ACTIONS ------------------- */

const fetchChatUrlSuccess = (state, { chatUrl }) => state.merge({ chatUrl });

const setChatUrlLoading = (state, { isloadingChatUrl }) =>
  state.merge({ isloadingChatUrl });

const setProviderErrors = (state, { error }) =>
  state.merge({
    errors: state.errors.concat(error),
  });

export const ProviderReducer = createReducer(INITIAL_STATE, {
  [Types.FETCH_CHAT_URL_SUCCESS]: fetchChatUrlSuccess,
  [Types.SET_CHAT_URL_LOADING]: setChatUrlLoading,
  [Types.SET_PROVIDER_ERRORS]: setProviderErrors,
});
