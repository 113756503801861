import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import moment from 'moment';
import { filters as filtersKeys } from 'constants/assessmentsFilters';

const { Types, Creators } = createActions(
  {
    fetchAssessments: [],
    fetchAssessmentsSuccess: ['assessmentsList'],
    setNavigation: ['navigation'],
    setAssessmentsErrors: ['error'],
    setAssessmentsLoading: ['isloadingAssessment'],
    fetchFilters: [],
    fetchFiltersSuccess: ['filters'],
    setIsFiltersReady: ['isFiltersReady'],
    setFiltersLoading: ['isloadingFilters'],
    setSelectedFilters: ['filters'],
    setDefaultSelectedFilters: ['filters'],
    applySelectedFilters: ['filters', 'search'],
    setOrdering: ['ordering'],
    setSearch: ['search'],
    resetFilters: [],
    resetAssessments: [],
    fetchViews: [],
    fetchViewsSuccess: ['views'],
    fetchDefaultViewSuccess: ['defaultView'],
    createView: ['filters', 'name'],
    updateView: ['id', 'filters', 'name'],
    deleteView: ['id'],
    setViewsLoading: ['isloadingViews'],
    setViewAsSelected: ['id'],
    setViewAsSelectedSuccess: ['selectedView'],
    setViewAsDefault: ['id'],
    applyView: ['view'],
    setIsViewDirty: ['isViewDirty'],
  },
  { prefix: '@BE-ASSESSMENTS/' }
);

export const AssessmentsActions = Creators;
export const AssessmentsTypes = Types;

export const DEFAULT_FILTERS = Immutable({
  [filtersKeys.providers]: [],
  [filtersKeys.organizations]: [],
  [filtersKeys.types]: [],
  [filtersKeys.status]: [
    {
      value: 'Pending',
      label: 'Pending',
      key: 'statuses-pending',
    },
  ],
  [filtersKeys.initialEvaluationDateRange]: null,
  [filtersKeys.scheduleDateRange]: {
    start: moment(new Date()).subtract(6, 'days').startOf('day').toDate(),
    end: new Date(),
  },
  [filtersKeys.includeDischarged]: false,
});

export const DEFAULT_VIEW = Immutable({
  id: 'default',
  name: 'Limber Default View',
  data: null,
  key: 'views-0',
});

export const INITIAL_STATE = Immutable({
  assessmentsList: null,
  isloadingAssessment: false,
  isloadingFilters: {
    [filtersKeys.providers]: false,
    [filtersKeys.organizations]: false,
    [filtersKeys.types]: false,
    [filtersKeys.status]: false,
  },
  isFiltersReady: false,
  filters: {
    [filtersKeys.providers]: [],
    [filtersKeys.organizations]: [],
    [filtersKeys.types]: [
      {
        value: 'intake',
        label: 'Intake',
        key: 'types-intake',
      },
      {
        value: 'risk_stratification',
        label: 'Protocol Intake',
        key: 'types-risk_stratification',
      },
      {
        value: 'reassessment',
        label: 'Reassessment',
        key: 'types-reassessment',
      },
    ],
    [filtersKeys.status]: [
      {
        value: 'Pending',
        label: 'Pending',
        key: 'statuses-pending',
      },
      {
        value: 'Completed',
        label: 'Completed',
        key: 'statuses-completed',
      },
      {
        value: 'Missed',
        label: 'Missed',
        key: 'statuses-missed',
      },
    ],
  },
  selectedFilters: DEFAULT_FILTERS,
  defaultSelectedFilters: DEFAULT_FILTERS,
  navigation: {
    currentPage: 1,
    totalPages: 1,
    limit: 20,
  },
  search: null,
  ordering: null,
  errors: [],
  views: null,
  isloadingViews: false,
  isViewDirty: false,
  selectedView: DEFAULT_VIEW,
  defaultView: DEFAULT_VIEW,
});

export const AssessmentsSelectors = {
  getAssessments: ({ assessments }) => assessments.assessmentsList,
  getAssessmentsLoading: ({ assessments }) => assessments.isloadingAssessment,
  getIsFiltersReady: ({ assessments }) => assessments.isFiltersReady,
  getFiltersIsLoading: ({ assessments }) => assessments.isloadingFilters,
  getFilters: ({ assessments }) => assessments.filters,
  getSelectedFilters: ({ assessments }) => assessments.selectedFilters,
  getDefaultSelectedFilters: ({ assessments }) =>
    assessments.defaultSelectedFilters,
  getNavigation: ({ assessments }) => assessments.navigation,
  getSearch: ({ assessments }) => assessments.search ?? '',
  getOrdering: ({ assessments }) => assessments.ordering,
  getErrors: ({ assessments }) => assessments.errors,
  getViews: ({ assessments }) => assessments.views,
  getViewsLoading: ({ assessments }) => assessments.isloadingViews,
  getSelectedView: ({ assessments }) => assessments.selectedView,
  getDefaultView: ({ assessments }) => assessments.defaultView,
  getIsViewDirty: ({ assessments }) => assessments.isViewDirty,
};

/* ------------- REDUCERS ACTIONS ------------------- */

const fetchAssessmentsSuccess = (state, { assessmentsList }) =>
  state.merge({ assessmentsList });

const setAssessmentsLoading = (state, { isloadingAssessment }) =>
  state.merge({ isloadingAssessment });

const setNavigation = (state, { navigation }) =>
  state.merge({
    navigation: {
      ...state.navigation,
      ...navigation,
    },
  });

const setSearch = (state, { search }) =>
  state.merge({
    search,
    navigation: {
      ...state.navigation,
      currentPage: 1,
    },
  });

const setAssessmentsErrors = (state, { error }) =>
  state.merge({
    errors: state.errors.concat(error),
    assessmentsList: [],
    navigation: INITIAL_STATE.navigation,
  });

const fetchFiltersSuccess = (state, { filters }) =>
  state.merge({
    filters: { ...state.filters, ...filters },
  });

const setFiltersLoading = (state, { isloadingFilters }) =>
  state.merge({
    isloadingFilters: { ...state.isloadingFilters, ...isloadingFilters },
  });

const setIsFiltersReady = (state, { isFiltersReady }) =>
  state.merge({
    isFiltersReady,
  });

const setSelectedFilters = (state, { filters }) =>
  state.merge({
    selectedFilters: { ...state.selectedFilters, ...filters },
  });

const setDefaultSelectedFilters = (state, { filters }) =>
  state.merge({
    defaultSelectedFilters: { ...state.defaultSelectedFilters, ...filters },
  });

const setOrdering = (state, { ordering }) => state.merge({ ordering });

const resetAssessments = (state) => state.merge({ assessmentsList: null });

const fetchViewsSuccess = (state, { views }) => state.merge({ views });

const fetchDefaultViewSuccess = (state, { defaultView }) =>
  state.merge({ defaultView });

const setViewsLoading = (state, { isloadingViews }) =>
  state.merge({ isloadingViews });

const setViewAsSelectedSuccess = (state, { selectedView }) =>
  state.merge({ selectedView });

const setIsViewDirty = (state, { isViewDirty }) => state.merge({ isViewDirty });

export const AssessmentsReducer = createReducer(INITIAL_STATE, {
  [Types.FETCH_ASSESSMENTS_SUCCESS]: fetchAssessmentsSuccess,
  [Types.SET_ASSESSMENTS_LOADING]: setAssessmentsLoading,
  [Types.SET_ASSESSMENTS_ERRORS]: setAssessmentsErrors,
  [Types.FETCH_FILTERS_SUCCESS]: fetchFiltersSuccess,
  [Types.SET_FILTERS_LOADING]: setFiltersLoading,
  [Types.SET_IS_FILTERS_READY]: setIsFiltersReady,
  [Types.RESET_ASSESSMENTS]: resetAssessments,
  [Types.SET_SELECTED_FILTERS]: setSelectedFilters,
  [Types.SET_DEFAULT_SELECTED_FILTERS]: setDefaultSelectedFilters,
  [Types.SET_NAVIGATION]: setNavigation,
  [Types.SET_SEARCH]: setSearch,
  [Types.SET_ORDERING]: setOrdering,
  [Types.FETCH_VIEWS_SUCCESS]: fetchViewsSuccess,
  [Types.FETCH_DEFAULT_VIEW_SUCCESS]: fetchDefaultViewSuccess,
  [Types.SET_VIEWS_LOADING]: setViewsLoading,
  [Types.SET_VIEW_AS_SELECTED_SUCCESS]: setViewAsSelectedSuccess,
  [Types.SET_IS_VIEW_DIRTY]: setIsViewDirty,
});
