/* eslint-disable camelcase */
import { createQueryParamsAsString } from 'helpers/requestHelpers';
import { getRequest, postRequest } from './base';

export const getEducationsPaginated = ({
  page = 1,
  limit = 20,
  orgOnly = true,
  favorites = null, //BOOL
  search = '' //STR
}) => {
  return getRequest(`/educations/paginated?${createQueryParamsAsString({
    page, limit, orgOnly, favorites, search
  })}`)
    .then(response => {
      if (response.status === 200) return response.data;
      return Promise.reject(response.statusText);
    })
};

export const setFavoriteEducation = async (education) => {
  return postRequest('/educations/favorite', education).then((response) => response.data);
};