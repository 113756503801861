import { getSagasBinds } from 'helpers/sagasHelper';
import { takeLatest } from 'redux-saga/effects';
// eslint-disable-next-line import/no-cycle
import OutcomeFeatureFlagsSagas from './featureFlagsSagas';
import { OutcomesFeatureFlagsTypes as Types } from './featureFlagsReducer';

const outcomesFeatureFlagTemplate = {
  data: [
    {
      type: Types.FETCH_OUTCOMES_FEATURE_FLAGS,
      action: takeLatest,
    },
  ],
  sagas: OutcomeFeatureFlagsSagas,
};

// eslint-disable-next-line import/prefer-default-export
export const outcomesFeatureFlagBinds = getSagasBinds(
  outcomesFeatureFlagTemplate
);
