import { takeLatest } from 'redux-saga/effects';
import { getSagasBinds } from 'helpers/sagasHelper';
import { NotesTypes as Types } from './notesReducer';
import NotesSagas from './notesSagas';

const notes = {
  data: [
    {
      type: Types.FETCH_NOTES_PDF,
      action: takeLatest,
    },
  ],
  sagas: NotesSagas,
};

// eslint-disable-next-line import/prefer-default-export
export const notesBinds = getSagasBinds(notes);
