import React from 'react';
import { createTheme } from '@mui/material/styles';

function useTheme() {
  return React.useMemo(() =>
    createTheme({
      palette: {
        primary: {
          main: '#2e2758',
        },
        secondary: {
          main: '#3c3c3c',
        },
      },
      typography: {
        fontFamily: 'Rubik-500',
      },

      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              borderRadius: '50px',
            },
          },
        },
        MuiPaper: {
          styleOverrides: {
            root: {
              borderRadius: '10px',
            },
          },
        },
      },
    })
  );
}

export default useTheme;
