import { call, put, select } from 'redux-saga/effects';
import { getNotesFromCasePDF } from 'services/api/pdf';

import {
  NotesTypes as Types,
  NotesActions as Actions,
  NotesSelectors as Selectors,
} from './notesReducer';

import { ParticipantSelectors } from '../participant/participantReducer';

function downloadPdfDoc(base64Data, fileName) {
  const downloadLink = document.createElement('a');
  downloadLink.href = `data:application/pdf;base64,${encodeURI(base64Data)}`;
  downloadLink.download = fileName || 'test doc';
  downloadLink.setAttribute('target', '_blank');
  downloadLink.click();
  downloadLink.remove();
}

function* fetchNotesPdf() {
  const participant = yield select(ParticipantSelectors.getParticipant);
  const caseSelected = yield select(ParticipantSelectors.getCaseSelected);
  const rangeStart = yield select(Selectors.getFromDate);
  const rangeEnd = yield select(Selectors.getToDate);

  try {
    yield put(Actions.startLoading());
    const response = yield call(
      getNotesFromCasePDF,
      participant.id,
      caseSelected.id,
      rangeStart,
      rangeEnd
    );

    const participantExternalId = participant.external_id || 'Not Specified';
    const caseExternalId = caseSelected.external_id || 'Not Specified';

    const pdfFileName = `${rangeStart.replaceAll(
      '-',
      ''
    )}-${rangeEnd.replaceAll(
      '-',
      ''
    )}-${participantExternalId}-${caseExternalId}.pdf`;
    downloadPdfDoc(response, pdfFileName);

    yield put(Actions.fetchNotesPdfSuccess());
  } catch (error) {
    yield put(Actions.fetchNotesPdfError(error));
  } finally {
    yield put(Actions.endLoading());
  }
}

export default () => ({
  [Types.FETCH_NOTES_PDF]: fetchNotesPdf,
});
