const KEY = 'limberPortalData';
export const TENANT_ID = 'tenantId';

export function getSessionData() {
  try {
    const initData = sessionStorage?.getItem(KEY);
    const data = JSON.parse(initData || '{}');
    return data;
  } catch {
    console.log('[sessionStorage]: there was a problem getting store');
    return {};
  }
}

export function getSessionDataByKey(keys) {
  try {
    if (!keys) {
      throw new Error('[sessionStorage]: requires keys');
    }

    // return object
    if (Array.isArray(keys)) {
      const data = getSessionData();
      return keys.reduce((a, c) => {
        if (data?.[c]) {
          return { ...a, [c]: data[c] };
        }
        return a;
      }, {});
    }

    // return single value
    if (typeof keys === 'string') {
      const data = getSessionData();
      return data?.[keys] || null;
    }
    return null;
  } catch (e) {
    console.log(e);
    return {};
  }
}

// `data` is just a generic object
export function setSessionData(data) {
  try {
    const sessionsData = getSessionData();
    const nextData = { ...sessionsData, ...data };
    sessionStorage.setItem(KEY, JSON.stringify(nextData));
    return nextData;
  } catch {
    console.log('[sessionStorage]: there was a problem setting store');
    return {};
  }
}

export function clearSessionData() {
  try {
    sessionStorage.removeItem(KEY);
    return {};
  } catch {
    console.log('[sessionStorage]: there was a problem clearing store');
    return {};
  }
}
