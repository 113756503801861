import { put, call } from 'redux-saga/effects';
import { getChatURL } from 'services/api/provider';
import {
  ProviderActions as Actions,
  ProviderTypes as Types,
} from './providerReducer';

function* fetchChatUrl({ participantId }) {
  if (participantId) {
    yield put(Actions.setChatUrlLoading(true));

    try {
      const response = yield call(getChatURL, participantId);

      yield put(Actions.fetchChatUrlSuccess(response));
    } catch (error) {
      yield put(Actions.fetchChatUrlSuccess(null));
      yield put(
        Actions.setProviderErrors({
          error,
          message: '[getParticipantOverview]: problem getting data',
        })
      );
    } finally {
      yield put(Actions.setChatUrlLoading(false));
    }
  } else {
    yield put(
      Actions.setProviderErrors({
        error: true,
        message: '[getParticipantOverview]: not participant id found',
      })
    );
  }
}

export default () => ({
  [Types.FETCH_CHAT_URL]: fetchChatUrl,
});
