export const STAGE = {
  Local: 'local',
  Development: 'development',
  QA: 'qa',
  Production: 'production',
};

export const stage = process.env.REACT_APP_LIMBER_STAGE || STAGE.Local;
export const auth0Domain = process.env.REACT_APP_LIMBER_AUTH0_DOMAIN;
export const auth0ClientId = process.env.REACT_APP_LIMBER_AUTH0_CLIENT_ID;
export const auth0Audience = process.env.REACT_APP_LIMBER_AUTH0_AUDIENCE;
export const auth0Callback = process.env.REACT_APP_LIMBER_AUTH0_CALLBACK;
