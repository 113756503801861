const URL = 'https://limberhealth.domo.com/embed/';

export const AUDIENCE_TYPES = {
  PRIVATE: 'private',
  PUBLIC: 'public',
};

export const CONTENT_TYPES = {
  CARD: 'card',
  DASHBOARD: 'dashboard',
};

export const getEmbedUrl = (
  id,
  elementType = CONTENT_TYPES.DASHBOARD,
  audience = AUDIENCE_TYPES.PRIVATE
) => `${URL}${elementType}/${audience}/${id}`;

export const getProgramaticFilteringEmbed = (embedToken, embedUrl) => `
  <html>
    <body>
    <form action="${embedUrl}" method="post">
    <input type="hidden" name="embedToken" value="${embedToken}" />
    </form>
    </body>
    <script>
      document.forms[0].submit();
    </script>
  </html>
`;
