import { getSagasBinds } from 'helpers/sagasHelper';
import { takeLatest } from 'redux-saga/effects';
import FeatureFlagsSagas from './featureFlagsSagas';
import { FeatureFlagsTypes as Types } from './featureFlagsReducer';

const featureFlagTemplate = {
  data: [
    {
      type: Types.FETCH_FEATURE_FLAGS,
      action: takeLatest,
    },
  ],
  sagas: FeatureFlagsSagas,
};

// eslint-disable-next-line import/prefer-default-export
export const featureFlagBinds = getSagasBinds(featureFlagTemplate);
