import { takeLatest, throttle, takeLeading } from 'redux-saga/effects';
import { getSagasBinds } from 'helpers/sagasHelper';
import { ParticipantsTypes as Types } from './participantsReducer';
import ParticipantsSagas from './participantsSagas';

const participantsTemplate = {
  data: [
    {
      type: Types.FETCH_PARTICIPANTS,
      action: takeLatest,
    },
    {
      type: Types.FETCH_FILTERS,
      action: takeLeading,
    },
    {
      type: Types.FETCH_PROVIDERS,
      action: takeLatest,
    },
    {
      type: Types.SET_SELECTED_FILTERS,
      action: throttle,
      time: 1000,
    },
    {
      type: Types.FETCH_VIEWS,
      action: takeLatest,
    },
    {
      type: Types.CREATE_VIEW,
      action: takeLatest,
    },
    {
      type: Types.DELETE_VIEW,
      action: takeLatest,
    },
    {
      type: Types.SET_VIEW_AS_SELECTED,
      action: takeLatest,
    },
    {
      type: Types.DELETE_VIEW,
      action: takeLatest,
    },
    {
      type: Types.SET_SEARCH,
      action: takeLatest,
    },
    {
      type: Types.UPDATE_PARTICIPANTS_LIST_AFTER_UPDATE,
      action: takeLatest,
    },
    {
      type: Types.FETCH_FIELDS,
      action: takeLatest,
    },
    {
      type: Types.FETCH_CARE_NAVIGATORS,
      action: takeLatest,
    },
  ],
  sagas: ParticipantsSagas,
};

// eslint-disable-next-line import/prefer-default-export
export const participantsBinds = getSagasBinds(participantsTemplate);
