import moment from 'moment';
import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions(
  {
    setIsDownloadAllNotesChecked: [
      'isDownloadAllNotesChecked',
      'caseCreateDate',
    ],
    resetNotes: [],
    setFromDate: ['fromDate', 'blur'],
    setToDate: ['toDate', 'blur'],
    setIsExportModalVisible: ['isExportModalVisible'],
    fetchNotesPdf: [],
    fetchNotesPdfSuccess: [],
    fetchNotesPdfError: ['error'],
    startLoading: [],
    endLoading: [],
  },
  { prefix: '@FE-NOTES/' }
);

export const NotesActions = Creators;
export const NotesTypes = Types;

export const INITIAL_STATE = Immutable({
  isDownloadAllNotesChecked: false,
  fromDate: '',
  toDate: '',
  isExportModalVisible: false,
  isLoading: false,
});

export const NotesSelectors = {
  getIsDownloadAllNotesChecked: ({ notes }) => notes.isDownloadAllNotesChecked,
  getFromDate: ({ notes }) => notes.fromDate,
  getToDate: ({ notes }) => notes.toDate,
  getIsExportModalVisible: ({ notes }) => notes.isExportModalVisible,
  getIsLoading: ({ notes }) => notes.isLoading,
};

/* ------------- REDUCERS ACTIONS ------------------- */

const setIsDownloadAllNotesChecked = (
  state,
  { isDownloadAllNotesChecked, caseCreateDate }
) => {
  const mergeState = { isDownloadAllNotesChecked };
  if (isDownloadAllNotesChecked === true) {
    const timeRegex = /T.*$/;
    const fromDate = caseCreateDate.replace(timeRegex, '');
    const toDate = new Date().toISOString().replace(timeRegex, '');
    mergeState.fromDate = fromDate;
    mergeState.toDate = toDate;
  }
  return state.merge(mergeState);
};

const resetNotes = (state) =>
  state.merge({
    ...INITIAL_STATE,
  });

const setToDate = (state, { toDate, blur }) => {
  if (toDate && blur) {
    const fromDate = state?.fromDate;
    // if `fromDate` has been set, make sure it's not AFTER `toDate`
    if (fromDate && moment(fromDate).isAfter(toDate)) {
      return state.merge({
        toDate,
        fromDate: toDate,
      });
    }
  }
  return state.merge({
    toDate,
  });
};

const setFromDate = (state, { fromDate, blur }) => {
  if (fromDate && blur) {
    const toDate = state?.toDate;
    // if `toDate` has been set, make sure it is not BEFORE `fromDate`
    if (toDate && moment(toDate).isBefore(fromDate)) {
      return state.merge({
        fromDate,
        toDate: fromDate,
      });
    }
  }
  return state.merge({
    fromDate,
  });
};

const startLoading = (state) =>
  state.merge({
    isLoading: true,
  });

const endLoading = (state) =>
  state.merge({
    isLoading: false,
  });

const setIsExportModalVisible = (state, { isExportModalVisible }) => {
  // on `false`, reset state
  if (isExportModalVisible === false) {
    return INITIAL_STATE;
  }
  return state.merge({
    isExportModalVisible,
  });
};

export const NotesReducer = createReducer(INITIAL_STATE, {
  [Types.SET_IS_DOWNLOAD_ALL_NOTES_CHECKED]: setIsDownloadAllNotesChecked,
  [Types.RESET_NOTES]: resetNotes,
  [Types.SET_FROM_DATE]: setFromDate,
  [Types.SET_TO_DATE]: setToDate,
  [Types.SET_IS_EXPORT_MODAL_VISIBLE]: setIsExportModalVisible,
  [Types.START_LOADING]: startLoading,
  [Types.END_LOADING]: endLoading,
});
