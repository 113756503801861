/* eslint-disable import/prefer-default-export */

import moment from 'moment';

export const getAllDaysOnWeekByDate = (date) => {
  const current = moment(date, 'MM-DD-YYYY');
  const startOfWeek = current.startOf('isoWeek');
  const dates = [];
  for (let i = 0; i < 7; i += 1) {
    dates.push(moment(startOfWeek).add(i, 'days').format('MM-DD-YYYY'));
  }

  return dates;
};

export const formatDate = (date) => {
  return moment(date).format('MM-DD-YYYY');
};
