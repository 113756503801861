import Immutable from 'seamless-immutable';

export const caseStatus = Immutable({
    ACTIVE: 'ACTIVE',
    PLANNED: 'PLANNED',
    ONHOLD: 'ONHOLD',
    FINISHED: 'FINISHED',
});

export const tagsCategoryIds = Immutable({
    INSURANCE: 1,
    PROVIDER_TYPES: 3,
    CN_TYPES: 4,
    REPORTING_QUALIFICATIONS: 5,
    TREATMENT_STYLE: 6,
});

export const IS_OPEN_CREATE_CONSTANT = 'create';

export const INVALID_PHONE_TEXT = 'Invalid Phone Number';
