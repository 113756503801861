import { filters as filtersKeys } from 'constants/assessmentsFilters';
import { OUTCOMES_USER_PORTAL_VIEW_TYPE } from 'constants/outcomes';
import { createOutcomesQueryParamsAsString } from 'helpers/requestHelpers';
import moment from 'moment';
import {
  deleteOutcomesAssessmentRequest,
  getOutcomesAssessmentRequest,
  patchOutcomesAssessmentRequest,
  postOutcomesAssessmentRequest,
  putOutcomesAssessmentRequest,
} from './baseOutcomes';

const filterToQueryParam = (filterValues) => {
  if (!filterValues || filterValues.length === 0) {
    return null;
  }
  return filterValues.map((provider) => provider.value);
};

const filterToDateRangeQueryParam = (filterValue) => {
  if (filterValue?.start && filterValue?.end) {
    return {
      startDate: filterValue.start.toISOString(),
      endDate: moment(filterValue.end).endOf('day').toISOString(),
    };
  }
  return { startDate: null, endDate: null };
};

const sortToOrderQueryParam = (sort) => {
  if (sort) {
    const [orderBy, orderDirectionNumber] = sort;
    return {
      orderBy,
      orderDirection: orderDirectionNumber === 1 ? 'ASC' : 'DESC',
    };
  }
  return { orderBy: null, orderDirection: null };
};

const mapPortalViewDateRangeFilterResponse = (dateRangeFilterValue) => {
  if (!dateRangeFilterValue) {
    return null;
  }
  return {
    start: new Date(dateRangeFilterValue.start),
    end: new Date(dateRangeFilterValue.end),
  };
};

const mapPortalViewResponse = (portalView) => {
  return {
    ...portalView,
    data: {
      ...portalView.data,
      [filtersKeys.initialEvaluationDateRange]:
        mapPortalViewDateRangeFilterResponse(
          portalView.data?.[filtersKeys.initialEvaluationDateRange]
        ),
      [filtersKeys.scheduleDateRange]: mapPortalViewDateRangeFilterResponse(
        portalView.data?.[filtersKeys.scheduleDateRange]
      ),
    },
  };
};

export const getAggregateAssessments = async ({
  search,
  filters,
  sort,
  tenantId,
  limit,
  page,
}) => {
  const config = {
    headers: { 'x-tenant-id': tenantId },
  };
  const { startDate: ieStartDate, endDate: ieEndDate } =
    filterToDateRangeQueryParam(
      filters[filtersKeys.initialEvaluationDateRange]
    );
  const { startDate: scheduleStartDate, endDate: scheduleEndDate } =
    filterToDateRangeQueryParam(filters[filtersKeys.scheduleDateRange]);
  const { orderBy, orderDirection } = sortToOrderQueryParam(sort);
  const queryParams = {
    search,
    limit,
    page,
    providerIds: filterToQueryParam(filters[filtersKeys.providers]),
    organizationIds: filterToQueryParam(filters[filtersKeys.organizations]),
    types: filterToQueryParam(filters[filtersKeys.types]),
    statuses: filterToQueryParam(filters[filtersKeys.status]),
    includeDischarged: filters[filtersKeys.includeDischarged],
    ieStartDate,
    ieEndDate,
    scheduleStartDate,
    scheduleEndDate,
    orderBy,
    orderDirection,
  };
  return getOutcomesAssessmentRequest(
    `api/provider/assessments/aggregate?${createOutcomesQueryParamsAsString(
      queryParams
    )}`,
    config
  ).then((response) => {
    if (response.status === 200) {
      return response.data;
    }
    return Promise.reject(response.statusText);
  });
};

export const getOutcomesFeatureFlags = async ({ tenantId }) => {
  const data = await getOutcomesAssessmentRequest(
    `/api/provider/tenants/${tenantId}/configurations`
  );
  return data.data;
};

export const getOutcomesPortalViewForCurrentUser = async () => {
  const data = await getOutcomesAssessmentRequest(
    `/api/provider/users/me/settings/portal-views`
  );
  const { defaultPortalViewId, portalViews } = data.data;
  return {
    defaultPortalViewId,
    portalViews: portalViews?.map(mapPortalViewResponse) || [],
  };
};

export const createOutcomesPortalViewForCurrentUser = async ({
  data,
  name,
}) => {
  const requestBody = {
    name,
    type: OUTCOMES_USER_PORTAL_VIEW_TYPE.AGGREGATE_ASSESSMENT,
    data,
  };
  const response = await postOutcomesAssessmentRequest(
    `/api/provider/users/me/settings/portal-views`,
    requestBody
  );

  return mapPortalViewResponse(response.data);
};

export const updateOutcomesPortalViewForCurrentUser = async ({
  id,
  data,
  name,
}) => {
  const requestBody = {
    name,
    data,
  };
  const response = await patchOutcomesAssessmentRequest(
    `/api/provider/users/me/settings/portal-views/${id}`,
    requestBody
  );

  return mapPortalViewResponse(response.data);
};

export const deleteOutcomesPortalViewForCurrentUser = async (id) => {
  return deleteOutcomesAssessmentRequest(
    `/api/provider/users/me/settings/portal-views/${id}`
  );
};

export const setDefaultOutcomesPortalViewForCurrentUser = async (id) => {
  const requestBody = {
    id,
  };
  const response = await putOutcomesAssessmentRequest(
    `/api/provider/users/me/settings/default-portal-view`,
    requestBody
  );
  return mapPortalViewResponse(response.data);
};
