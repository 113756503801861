import { call, put } from 'redux-saga/effects';
import getDomoUrl from 'services/api/domo';
import { getProgramaticFilteringEmbed } from 'constants/domo';
import {
  DashboardsActions as Actions,
  DashboardsTypes as Types,
} from './dashboardsReducer';

function* fetchDashboardData(action) {
  const { id, elementType } = action;
  yield put(Actions.setIsDashboardsLoading({ [id]: true }));

  try {
    const { embedToken, embedUrl, alive } = yield call(
      getDomoUrl,
      id,
      elementType
    );
    const response = getProgramaticFilteringEmbed(embedToken, embedUrl);

    yield put(
      Actions.fetchDashboardDataSuccess({
        [id]: {
          data: response,
          alive,
        },
      })
    );
  } catch (error) {
    yield put(
      Actions.setDashboardsErrors({
        [id]: {
          error: `[fetchDashboardData error]: id [${id}] ${error}`,
          message:
            'Oops!, something goes worng loading the data please contact our support team.',
        },
      })
    );
  } finally {
    yield put(Actions.setIsDashboardsLoading({ [id]: false }));
  }
}

export default () => ({
  [Types.FETCH_DASHBOARD_DATA]: fetchDashboardData,
});
