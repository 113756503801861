/* eslint-disable import/prefer-default-export */
import Immutable from 'seamless-immutable';

export const filters = Immutable({
  favoritesOnly: 'FAVORITES_ONLY',
  aliasOnly: 'ALIAS_ONLY',
  sharedExercises: 'SHARED_EXERCISES',
  specialty: 'SPECIALTY',
  bodyRegion: 'BODY_REGION',
  muscleGroup: 'MUSCLE_GROUP',
  objective: 'OBJECTIVE',
  position: 'POSITION',
  equipment: 'EQUIPMENT',
});
