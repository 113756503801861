import { getSagasBinds } from 'helpers/sagasHelper';
import { takeLatest } from 'redux-saga/effects';
import ProviderSagas from './providerSagas';
import { ProviderTypes as Types } from './providerReducer';

const providerTemplate = {
  data: [
    {
      type: Types.FETCH_CHAT_URL,
      action: takeLatest,
    },
  ],
  sagas: ProviderSagas,
};

// eslint-disable-next-line import/prefer-default-export
export const providerBinds = getSagasBinds(providerTemplate);
