import { takeLatest, throttle, takeLeading } from 'redux-saga/effects';
import { getSagasBinds } from 'helpers/sagasHelper';
import { AssessmentsTypes as Types } from './assessmentsReducer';
import AssessmentsSagas from './assessmentsSagas';

const assessmentsTemplate = {
  data: [
    {
      type: Types.FETCH_ASSESSMENTS,
      action: takeLatest,
    },
    {
      type: Types.FETCH_FILTERS,
      action: takeLeading,
    },
    {
      type: Types.APPLY_SELECTED_FILTERS,
      action: throttle,
      time: 1000,
    },
    {
      type: Types.RESET_FILTERS,
      action: takeLatest,
    },
    {
      type: Types.FETCH_VIEWS,
      action: takeLatest,
    },
    {
      type: Types.CREATE_VIEW,
      action: takeLatest,
    },
    {
      type: Types.UPDATE_VIEW,
      action: takeLatest,
    },
    {
      type: Types.SET_VIEW_AS_SELECTED,
      action: takeLatest,
    },
    {
      type: Types.SET_VIEW_AS_DEFAULT,
      action: takeLatest,
    },
    {
      type: Types.DELETE_VIEW,
      action: takeLatest,
    },
    {
      type: Types.APPLY_VIEW,
      action: takeLatest,
    },
  ],
  sagas: AssessmentsSagas,
};

// eslint-disable-next-line import/prefer-default-export
export const assessmentsBinds = getSagasBinds(assessmentsTemplate);
