/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { DefaultTenant } from 'constants/defaultValues';

let savedRoles;

export const getRole = async ({
  token = null,
  tenant = DefaultTenant,
  signal,
}) => {
  if (savedRoles && tenant === DefaultTenant) {
    return Promise.resolve(savedRoles);
  }

  if (!token) return Promise.reject(new Error('No token given'));

  const config = {
    headers: {
      Authorization: token.includes('Bearer ') ? token : `Bearer ${token}`,
      'x-tenant': tenant,
    },
  };

  if (signal) {
    config.signal = signal;
  }

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_LIMBER_API_BASE_URL}/users/getrol`,
      config
    );
    // FIXME: all the verification process is kinda odd need to be refactor to add speed and consistence.
    savedRoles = [response.data];
    return Promise.resolve(savedRoles);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const resetRole = () => {
  savedRoles = null;
};

export const getSavedRole = () => {
  return savedRoles;
};

// For getting the list of service types or finding a service type by a feature flag
export const getServiceTypesAvailableToTenant = () => {
  return getSavedRole()[0].tenant.serviceTypesAvailableToTenant;
};

// Map to support code that defines service types by slug instead of feature flags
export const serviceTypeIDMap = () => ({
  HEP_ONLY: getServiceTypesAvailableToTenant().find(({ slug }) => slug === "HEP_ONLY")?.id,
  RTM: getServiceTypesAvailableToTenant().find(({ slug }) => slug === "RTM")?.id,
  SCREENING: getServiceTypesAvailableToTenant().find(({ slug }) => slug === "SCREENING")?.id,
});
