import axios from 'axios';
import { DefaultTenant } from 'constants/defaultValues';

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_LIMBER_API_BASE_URL,
});

axiosInstance.defaults.headers.post['Content-Type'] =
  'application/json;charset=utf-8';
axiosInstance.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
axiosInstance.defaults.headers.common['x-tenant'] = DefaultTenant;

export const getRequest = (path, config = {}) => {
  return axiosInstance.get(path, config);
};

export const putRequest = (path, body) => {
  return axiosInstance.put(path, body);
};

export const postRequest = (path, body, options) => {
  return axiosInstance.post(path, body, options);
};

export const deleteRequest = (path, body) => {
  return axiosInstance.delete(path, body);
};
