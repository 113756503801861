import { DefaultTenant } from 'constants/defaultValues';
import { object, string } from 'yup';

export function getSelectedTenantId(selectedTenantId, tenantList) {
  if (!selectedTenantId || selectedTenantId === DefaultTenant) {
    return tenantList?.[0]?.id;
  }
  const selectedTenant = tenantList?.find(({ id }) => id === selectedTenantId);
  return selectedTenant?.id;
}

export function getSelectOptionForView(view) {
  return {
    value: view.id,
    label: view.name,
    view,
  };
}

export function getViewSchema(existingViews) {
  return object({
    name: string()
      .test({
        message: () =>
          'This view name already exists. Please choose a different name.',
        test(value) {
          return (
            existingViews?.find((view) => view.name === value?.trim()) ===
            undefined
          );
        },
      })
      .required('View name is required.'),
  });
}
