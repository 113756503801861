import isArray from 'lodash/isArray';

import { deleteRequest, getRequest, postRequest, putRequest } from './base';

const createQuery = (obj) => {
  return Object.entries(obj).reduce((a, [k, v], index) => {
    const prefix = index !== 0 ? '&' : '';
    if (isArray(v)) {
      const arrayValues = v.map((value) => `${k}=${value}`).join('&');
      return a.concat(`${prefix}${arrayValues}`);
    }
    return a.concat(`${prefix}${k}=${v}`);
  }, '');
};

export const getProgramTemplates = async ({ filters = {} }) => {
  const query = createQuery(filters);
  const url = query ? `/program-templates?${query}` : '/program-templates';
  return getRequest(url)
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
      throw Error('No data returned');
    })
    .catch((e) => {
      return null;
    });
};

export const postProgramTemplate = async (body) =>
  postRequest(`/program-templates`, body)
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error('No data returned');
    })
    .catch((e) => {
      return null;
    });

export const putProgramTemplate = async ({ id, body }) =>
  putRequest(`/program-templates/${id}`, body)
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error('No data returned');
    })
    .catch((e) => {
      return null;
    });

export const deleteProgramTempalte = async (id) =>
  deleteRequest(`/program-templates/${id}`)
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error('No data returned');
    })
    .catch((e) => {
      return null;
    });

export const getProgramTemplate = async (id) =>
  getRequest(`/program-templates/${id}`)
    .then((response) => {
      if (response.status === 200) {
        const builderData = JSON.parse(response?.data?.builderData || {});
        return { ...response.data, builderData };
      }
      throw Error('No data returned');
    })
    .catch((e) => {
      return null;
    });

export const setProgramTemplateFavorite = async ({ id, isFavorite }) =>
  postRequest(`/program-templates-favorites`, {
    programTemplateId: id,
    isFavorite: !isFavorite,
  })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error('No data returned');
    })
    .catch((e) => {
      return null;
    });

export const getProgramTags = async () =>
  getRequest(`/program-tags`)
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
      throw Error('No data returned');
    })
    .catch((e) => {
      return null;
    });

export const getProgramTemplateFilters = async ({ filters = [] }) => {
  const query = filters ? createQuery({ filters }) : false;
  const url = query
    ? `/program-templates/filters?${query}`
    : `/program-templates/filters`;
  return getRequest(url)
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error('No data returned');
    })
    .catch((e) => {
      return null;
    });
};
