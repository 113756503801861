export const createQueryParamsAsString = (queryParams) => {
  return Object.keys(queryParams)
    .filter((key) => !!queryParams[key])
    .map((key) => `${key}=${queryParams[key]}`)
    .join('&');
};

export const createOutcomesQueryParamsAsString = (queryParams) => {
  return Object.entries(queryParams)
    .reduce((currentParams, [key, value]) => {
      if (value === null || value === undefined || value === '') {
        return currentParams;
      }
      if (Array.isArray(value)) {
        currentParams.push(
          value.map((arrayItemValue) => `${key}=${arrayItemValue}`).join('&')
        );
      } else {
        currentParams.push(`${key}=${value}`);
      }
      return currentParams;
    }, [])
    .join('&');
};
