import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions(
  {
    fetchParticipantFlags: ['id'],
    fetchParticipantFlagsSuccess: ['participantFlagsList'],
    fetchParticipantFlagsError: ['error'],
    setParticipantFlagsLoading: ['isloadingParticipantFlags'],
    resolveParticipantFlag: ['participantId', 'flagId'],
    setIsResolvingFlag: ['isResolvingFlag'],
    resolveFlagSuccess: ['resolved'],
    setResolveFlagError: ['error'],
  },
  { prefix: '@BE-PARTICIPANT-FLAGS/' }
);

export const ParticipantFlagsActions = Creators;
export const ParticipantFlagsTypes = Types;

export const INITIAL_STATE = Immutable({
  participantFlagsList: [],
  isloadingParticipantFlags: false,
  error: null,

  isResolvingFlag: false,
  resolveFlagError: null,
});

export const ParticipantFlagsSelectors = {
  getParticipantFlags: ({ participantFlags }) =>
    participantFlags.participantFlagsList,
  getParticipantFlagsLoading: ({ participantFlags }) =>
    participantFlags.isloadingParticipantFlags,
  getErrors: ({ participantFlags }) => participantFlags.error,
  getIsResolvingFlag: ({ participantFlags }) =>
    participantFlags.isResolvingFlag,
  getResolveFlagError: ({ participantFlags }) =>
    participantFlags.resolveFlagError,
};

/* ------------- REDUCERS ACTIONS ------------------- */

const setParticipantFlagsLoading = (state, { isloadingParticipantFlags }) =>
  state.merge({ isloadingParticipantFlags });

const fetchParticipantFlagsSuccess = (state, { participantFlagsList }) =>
  state.merge({ participantFlagsList });

const fetchParticipantFlagsErrors = (state, { error }) =>
  state.merge({
    error,
  });
const setIsResolvingParticipantFlag = (state, { isResolvingFlag }) =>
  state.merge({ isResolvingFlag });

const setResolveFlagError = (state, { error }) =>
  state.merge({ resolveFlagError: error });

export const ParticipantFlagsReducer = createReducer(INITIAL_STATE, {
  [Types.FETCH_PARTICIPANT_FLAGS_SUCCESS]: fetchParticipantFlagsSuccess,
  [Types.SET_PARTICIPANT_FLAGS_LOADING]: setParticipantFlagsLoading,
  [Types.FETCH_PARTICIPANT_FLAGS_ERROR]: fetchParticipantFlagsErrors,

  [Types.SET_IS_RESOLVING_FLAG]: setIsResolvingParticipantFlag,
  [Types.SET_RESOLVE_FLAG_ERROR]: setResolveFlagError,
});
