import { takeLatest } from 'redux-saga/effects';
import { getSagasBinds } from 'helpers/sagasHelper';
import { HepTypes as Types } from './hepReducer';
import HepSagas from './hepSagas';

const hepTemplate = {
  data: [
    {
      type: Types.FETCH_HEP_TEMPLATES,
      action: takeLatest,
    },
    {
      type: Types.FETCH_PROGRAM_PRINT,
      action: takeLatest,
    },
    {
      type: Types.CREATE_HEP,
      action: takeLatest,
    },
    {
      type: Types.GET_HEP_TO_EDIT,
      action: takeLatest,
    }
  ],
  sagas: HepSagas,
};

// eslint-disable-next-line import/prefer-default-export
export const HepBinds = getSagasBinds(hepTemplate);
