import { takeLatest } from 'redux-saga/effects';
import { getSagasBinds } from 'helpers/sagasHelper';
import { ParticipantFlagsTypes as Types } from './participantFlagsReducer';
import ParticipantFlagsSagas from './participantFlagsSagas';

const participantFlagsTemplate = {
  data: [
    {
      type: Types.FETCH_PARTICIPANT_FLAGS,
      action: takeLatest,
    },
    {
      type: Types.RESOLVE_PARTICIPANT_FLAG,
      action: takeLatest,
    },
  ],
  sagas: ParticipantFlagsSagas,
};

// eslint-disable-next-line import/prefer-default-export
export const participantFlagsBinds = getSagasBinds(participantFlagsTemplate);
