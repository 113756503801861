/* eslint-disable import/prefer-default-export */
import { takeLatest } from 'redux-saga/effects';
import { getSagasBinds } from 'helpers/sagasHelper';
import { DashboardsTypes as Types } from './dashboardsReducer';
import DashboardsSagas from './dashboardsSagas';

const DashboardsTemplate = {
  data: [
    {
      type: Types.FETCH_DASHBOARD_DATA,
      action: takeLatest,
    },
  ],
  sagas: DashboardsSagas,
};

export const DashboardsBinds = getSagasBinds(DashboardsTemplate);
