export const filterRoots = {
  search: 'SEARCH',
  filters: 'FILTERS',
  sagas: 'SAGAS',
  fetchFilters: 'FETCH_FILTERS',
  setView: 'SET_VIEW',
  fetchGroups: 'FETCH_GROUPS',
  fetchOrganizations: 'FETCH_ORGANIZATIONS',
  fetchProviders: 'FETCH_PROVIDERS',
};

export const viewsDefaults = {
  first: -1,
  default: -2,
};
