import moment from 'moment';

export const getWeekDates = (date) => {
  const now = date ? new Date(date) : new Date();

  const dayOfWeek = now.getDay();

  const firstDayOfWeek = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() - dayOfWeek + 1
  );

  const lastDayOfWeek = new Date(
    firstDayOfWeek.getFullYear(),
    firstDayOfWeek.getMonth(),
    firstDayOfWeek.getDate() + 6
  );

  firstDayOfWeek.setHours(0, 0, 0, 0);
  lastDayOfWeek.setHours(0, 0, 0, 0);

  return [firstDayOfWeek, lastDayOfWeek];
};

export const formatProgressData = (data) => {
  const progressData = [0, 0, 0, 0, 0, 0, 0];
  if (Array.isArray(data)) {
    const reduceData = data.map(
      ({ created_at: createdAt, completed_sessions: completedSessions }) => ({
        date: moment().local(createdAt),
        completedSessions,
      })
    );

    reduceData.forEach((currentProgressObject) => {
      const dayOfWeek = currentProgressObject.date.isoWeekday() - 1;
      const index = dayOfWeek < 0 ? 6 : dayOfWeek;
      progressData[index] += currentProgressObject.completedSessions;
    });
  }

  return progressData;
};
