import { filters as filtersKeys } from 'constants/assessmentsFilters';
import { DefaultProviderExternalId, UserRole } from 'constants/defaultValues';
import { filterRoots } from 'constants/filterEnums';
import { sortOrganizations } from 'helpers/organizationsHelpers';
import { getSelectedTenantId } from 'helpers/outcomesHelpers';
import { all, call, put, select } from 'redux-saga/effects';
import Immutable from 'seamless-immutable';
import {
  createOutcomesPortalViewForCurrentUser,
  deleteOutcomesPortalViewForCurrentUser,
  getAggregateAssessments,
  getOutcomesPortalViewForCurrentUser,
  setDefaultOutcomesPortalViewForCurrentUser,
  updateOutcomesPortalViewForCurrentUser,
} from 'services/api/outcomes';
import { getGroups, getProviders } from 'services/api/participant';
import { OutcomesFeatureFlagsSelectors } from 'store/outcomes-feature-flags';
import { UserSelectors } from 'store/user';
import Swal from 'sweetalert2';
import {
  AssessmentsActions as Actions,
  DEFAULT_VIEW,
  INITIAL_STATE,
  AssessmentsSelectors as Selectors,
  AssessmentsTypes as Types,
} from './assessmentsReducer';

function* fetchAssessments() {
  const isFetchAssessmentsLoading = yield select(
    Selectors.getAssessmentsLoading
  );
  if (isFetchAssessmentsLoading) {
    return;
  }

  yield put(Actions.setAssessmentsLoading(true));
  const selectedTenantId = yield select(UserSelectors.getCurrentTenant);
  const tenantList = yield select(UserSelectors.getTenantList);
  const tenantId = getSelectedTenantId(selectedTenantId, tenantList);
  if (!tenantId) {
    return;
  }
  const sort = yield select(Selectors.getOrdering);
  const search = yield select(Selectors.getSearch);
  const filters = yield select(Selectors.getSelectedFilters);
  const navigation = yield select(Selectors.getNavigation);

  try {
    const params = {
      tenantId,
      search,
      filters,
      sort,
      page: navigation.currentPage - 1,
      limit: navigation.limit,
    };

    const response = yield call(getAggregateAssessments, params);
    yield all([
      put(Actions.fetchAssessmentsSuccess(response.data)),
      put(
        Actions.setNavigation({
          currentPage: navigation.currentPage,
          totalPages: Math.ceil(response.totalItems / navigation.limit),
        })
      ),
    ]);
  } catch (error) {
    yield put(
      Actions.setAssessmentsErrors(`[fetchAssessments error]: ${error}`)
    );
  } finally {
    yield put(Actions.setAssessmentsLoading(false));
  }
}

function* getCurrentProvider({ allProviders }) {
  const role = yield select(UserSelectors.getUserRole);
  if (role.role_id === UserRole.Provider) {
    return {
      value: role.user_id,
      label: role.full_name,
      key: `providers-${role.user_id}`,
    };
  }
  const defaultProvider = allProviders?.find(
    (provider) => provider.external_id === DefaultProviderExternalId
  );
  return defaultProvider ?? allProviders?.[0];
}

let providerController;

function* fetchProviders({ groupIds, orgIds }) {
  if (providerController) {
    providerController.abort();
  }

  yield put(Actions.setFiltersLoading({ [filtersKeys.providers]: true }));
  try {
    providerController = new AbortController();

    const response = yield call(
      getProviders,
      groupIds,
      orgIds,
      providerController.signal
    );

    const providers = response.map((item) => ({
      value: item.user_id,
      label: `${item.first_name} ${item.last_name}`,
      key: `providers-${item.user_id}`,
      external_id: item.external_id,
    }));
    const outcomesFeatures = yield select(
      OutcomesFeatureFlagsSelectors.getOutcomesFeatureFlags
    );
    const currentProvider = outcomesFeatures.IS_BIG_CLIENT
      ? yield getCurrentProvider({
          allProviders: providers,
        })
      : null;
    yield all([
      put(
        Actions.setDefaultSelectedFilters({
          [filtersKeys.providers]: currentProvider ? [currentProvider] : [],
        })
      ),
      put(
        Actions.fetchFiltersSuccess({
          [filtersKeys.providers]: providers,
        })
      ),
    ]);
  } catch (error) {
    yield put(Actions.setAssessmentsErrors(`[fetchProviders error]: ${error}`));
  } finally {
    yield put(Actions.setFiltersLoading({ [filtersKeys.providers]: false }));
  }
}

export function* fetchOrganizations() {
  yield put(Actions.setFiltersLoading({ [filtersKeys.organizations]: true }));

  try {
    const response = yield call(getGroups);
    const organizations = response.map((item) => ({
      value: item.id,
      label: item.name,
      key: `organizations-${item.id}`,
    }));

    yield all([
      put(
        Actions.fetchFiltersSuccess({
          [filtersKeys.organizations]: sortOrganizations(organizations),
        })
      ),
    ]);
  } catch (error) {
    yield put(
      Actions.setAssessmentsErrors(`[fetchOrganizations error]: ${error}`)
    );
  } finally {
    yield put(
      Actions.setFiltersLoading({ [filtersKeys.organizations]: false })
    );
  }
}

function* fetchFilters() {
  try {
    const role = yield select(UserSelectors.getUserRole);
    const calls = [fetchProviders({ from: filterRoots.fetchFilters })];
    if (
      role.role_id === UserRole.Provider ||
      role.role_id === UserRole.TenantAdmin ||
      role.role_id === UserRole.CareNavigator ||
      role.role_id === UserRole.Admin
    ) {
      calls.unshift(fetchOrganizations({ from: filterRoots.fetchFilters }));
    }
    yield all(calls);
  } catch (error) {
    yield put(Actions.setAssessmentsErrors(`[fetchFilters error]: ${error}`));
  } finally {
    yield put(Actions.setIsFiltersReady(true));
  }
}

function* applySelectedFilters({ filters, search }) {
  const filterKey = Object.keys(filters)[0];
  let filtersToSet = {};

  const selectedFilters = yield select(Selectors.getSelectedFilters);

  if (Object.keys(filters).length > 1) {
    filtersToSet = selectedFilters.merge(filters);
  } else {
    filtersToSet = selectedFilters.merge({
      [filterKey]: filters[filterKey],
    });
  }
  yield put(Actions.setSelectedFilters(filtersToSet));
  if (search !== undefined) {
    yield put(Actions.setSearch(search));
  }
  const providerChanges =
    JSON.stringify(filtersToSet[filtersKeys.providers]) !==
    JSON.stringify(selectedFilters[filtersKeys.providers]);

  const organizationChanges =
    JSON.stringify(filtersToSet[filtersKeys.organizations]) !==
    JSON.stringify(selectedFilters[filtersKeys.organizations]);

  const typeChanges =
    JSON.stringify(filtersToSet[filtersKeys.types]) !==
    JSON.stringify(selectedFilters[filtersKeys.types]);

  const statusChanges =
    JSON.stringify(filtersToSet[filtersKeys.status]) !==
    JSON.stringify(selectedFilters[filtersKeys.status]);

  const isNewInitialEvaluationDateRangeValid =
    (filtersToSet[filtersKeys.initialEvaluationDateRange]?.start &&
      filtersToSet[filtersKeys.initialEvaluationDateRange]?.end) ||
    filtersToSet[filtersKeys.initialEvaluationDateRange] === null;

  const initialEvaluationDateRangeChanges =
    isNewInitialEvaluationDateRangeValid &&
    JSON.stringify(filtersToSet[filtersKeys.initialEvaluationDateRange]) !==
      JSON.stringify(selectedFilters[filtersKeys.initialEvaluationDateRange]);

  const isNewScheduleDateRangeValid =
    (filtersToSet[filtersKeys.scheduleDateRange]?.start &&
      filtersToSet[filtersKeys.scheduleDateRange]?.end) ||
    filtersToSet[filtersKeys.scheduleDateRange] === null;

  const scheduleDateRangeChanges =
    isNewScheduleDateRangeValid &&
    JSON.stringify(filtersToSet[filtersKeys.scheduleDateRange]) !==
      JSON.stringify(selectedFilters[filtersKeys.scheduleDateRange]);

  const includeDischargedChanges =
    JSON.stringify(filtersToSet[filtersKeys.includeDischarged]) !==
    JSON.stringify(selectedFilters[filtersKeys.includeDischarged]);

  const currentSearch = yield select(Selectors.getSearch);
  const searchChanged = search !== undefined && currentSearch !== search;

  if (
    providerChanges ||
    organizationChanges ||
    typeChanges ||
    statusChanges ||
    initialEvaluationDateRangeChanges ||
    scheduleDateRangeChanges ||
    includeDischargedChanges ||
    searchChanged
  ) {
    yield put(Actions.fetchAssessments());
  }
}

function* resetFilters() {
  const defaultSelectedFilters = yield select(
    Selectors.getDefaultSelectedFilters
  );
  yield all([
    put(Actions.setViewAsSelectedSuccess(DEFAULT_VIEW)),
    put(Actions.setNavigation(INITIAL_STATE.navigation)),
    put(Actions.setOrdering(INITIAL_STATE.ordering)),
    put(Actions.setIsViewDirty(true)),
  ]);
  yield put(
    Actions.applySelectedFilters(defaultSelectedFilters, INITIAL_STATE.search)
  );
}

function* applyView({ view }) {
  if (view.id === DEFAULT_VIEW.id) {
    const defaultSelectedFilters = yield select(
      Selectors.getDefaultSelectedFilters
    );
    yield all([
      put(Actions.setViewAsSelectedSuccess(DEFAULT_VIEW)),
      put(Actions.setNavigation(INITIAL_STATE.navigation)),
      put(Actions.setOrdering(INITIAL_STATE.ordering)),
      put(Actions.setIsViewDirty(false)),
    ]);
    yield put(Actions.applySelectedFilters(defaultSelectedFilters));
    return;
  }
  const selectedView = Immutable.asMutable(view, { deep: true });
  yield all([
    put(Actions.setNavigation(INITIAL_STATE.navigation)),
    put(Actions.setOrdering(INITIAL_STATE.ordering)),
    put(Actions.setViewAsSelectedSuccess(selectedView)),
    put(Actions.setIsViewDirty(false)),
  ]);
  yield put(Actions.applySelectedFilters(selectedView.data));
}

function* fetchViews() {
  yield put(Actions.setViewsLoading(true));
  try {
    const response = yield call(getOutcomesPortalViewForCurrentUser);
    const { portalViews: views, defaultPortalViewId } = response;
    const defaultView = defaultPortalViewId
      ? views.find((view) => view.id === defaultPortalViewId)
      : DEFAULT_VIEW;
    yield all([
      put(Actions.fetchViewsSuccess([DEFAULT_VIEW, ...views])),
      put(Actions.fetchDefaultViewSuccess(defaultView)),
    ]);
    yield put(Actions.setViewAsSelected(defaultView.id));
  } catch (error) {
    yield put(Actions.setAssessmentsErrors(`[fetchViews error]: ${error}`));
  } finally {
    yield put(Actions.setViewsLoading(false));
  }
}

function* createView({ filters, name }) {
  yield put(Actions.setViewsLoading(true));
  try {
    const views = yield select(Selectors.getViews);
    const createdView = yield call(createOutcomesPortalViewForCurrentUser, {
      data: filters,
      name: name.trim(),
    });
    Swal.fire('Success', `Views was added successfully`, 'success');
    yield all([
      put(Actions.fetchViewsSuccess([...views, createdView])),
      put(Actions.setViewAsSelected(createdView.id)),
      put(Actions.setIsViewDirty(false)),
    ]);
  } catch (error) {
    Swal.fire('Error', `Views can't be added correctly`, 'error');
    yield put(Actions.setAssessmentsErrors(`[createView error]: ${error}`));
  } finally {
    yield put(Actions.setViewsLoading(false));
  }
}

function* updateView({ id, filters, name }) {
  yield put(Actions.setViewsLoading(true));

  try {
    const views = yield select(Selectors.getViews);
    const updatedView = yield call(updateOutcomesPortalViewForCurrentUser, {
      id,
      data: filters,
      name: name?.trim(),
    });
    Swal.fire('Success', `Views was updated successfully`, 'success');
    const updateViews = views.reduce((acc, view) => {
      if (view.id === updatedView.id) {
        acc.push(updatedView);
      } else {
        acc.push(view);
      }
      return acc;
    }, []);

    yield put(Actions.fetchViewsSuccess(updateViews));
  } catch (error) {
    Swal.fire('Error', `Views can't be updated correctly`, 'error');
    yield put(Actions.setAssessmentsErrors(`[createView error]: ${error}`));
  } finally {
    yield put(Actions.setViewsLoading(false));
  }
}

function* deleteView({ id }) {
  const selectedView = yield select(Selectors.getSelectedView);
  yield put(Actions.setViewsLoading(true));

  try {
    yield call(deleteOutcomesPortalViewForCurrentUser, id);

    Swal.fire('Success', `Views was removed successfully`, 'success');

    const views = yield select(Selectors.getViews);
    const updateViews = views.filter((view) => view.id !== id);
    yield put(Actions.fetchViewsSuccess(updateViews));
    if (selectedView?.id === id) {
      yield all([
        put(Actions.setViewAsSelected(DEFAULT_VIEW.id)),
        put(Actions.fetchDefaultViewSuccess(DEFAULT_VIEW)),
        put(Actions.setIsViewDirty(false)),
      ]);
    }
  } catch (error) {
    Swal.fire('Error', `Views can't be removed correctly`, 'error');
    yield put(Actions.setAssessmentsErrors(`[createView error]: ${error}`));
  } finally {
    yield put(Actions.setViewsLoading(false));
  }
}

function* setViewAsDefault({ id }) {
  yield put(Actions.setViewsLoading(true));

  try {
    const newDefaultView = yield call(
      setDefaultOutcomesPortalViewForCurrentUser,
      DEFAULT_VIEW.id === id ? null : id
    );
    Swal.fire('Success', `Set view as default successfully`, 'success');
    const newDefaultViewOption = newDefaultView?.id
      ? newDefaultView
      : DEFAULT_VIEW;

    yield put(Actions.fetchDefaultViewSuccess(newDefaultViewOption));
  } catch (error) {
    Swal.fire('Error', `Set view as default failed with errors`, 'error');
    yield put(
      Actions.setAssessmentsErrors(`[setViewAsDefault error]: ${error}`)
    );
  } finally {
    yield put(Actions.setViewsLoading(false));
  }
}

function* setViewAsSelected({ id }) {
  if (id === DEFAULT_VIEW.id) {
    yield put(Actions.applyView(DEFAULT_VIEW));
    return;
  }
  const views = yield select(Selectors.getViews);
  const foundView = views?.find((item) => item.id === id);
  if (!foundView) {
    return;
  }
  yield put(Actions.applyView(foundView));
}

export default () => ({
  [Types.FETCH_ASSESSMENTS]: fetchAssessments,
  [Types.FETCH_FILTERS]: fetchFilters,
  [Types.FETCH_PROVIDERS]: fetchProviders,
  [Types.APPLY_SELECTED_FILTERS]: applySelectedFilters,
  [Types.RESET_FILTERS]: resetFilters,
  [Types.FETCH_VIEWS]: fetchViews,
  [Types.CREATE_VIEW]: createView,
  [Types.UPDATE_VIEW]: updateView,
  [Types.DELETE_VIEW]: deleteView,
  [Types.SET_VIEW_AS_SELECTED]: setViewAsSelected,
  [Types.SET_VIEW_AS_DEFAULT]: setViewAsDefault,
  [Types.APPLY_VIEW]: applyView,
});
