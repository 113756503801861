import { getRequest, postRequest } from './base';

export const getChatURL = (userId) => {
  return getRequest(`chat/org?user_id=${userId}`).then((response) => {
    if (response.status === 200) return response.data;
    return Promise.reject(response.statusText);
  });
};

export const getOrganizationProviders = (orgId) => {
  return getRequest(`providers?orgId=${orgId}`).then((response) => {
    // console.log('response', response);
    if (response.status === 200) return response.data;
    return Promise.reject(response.statusText);
  });
};

export const getMyOrganizationProviders = () => {
  return getRequest(`providers?limit=1000`).then((response) => {
    // console.log('response', response);
    if (response.status === 200) return response.data;
    return Promise.reject(response.statusText);
  });
};

export const updateLastLogin = (id) => {
  return postRequest('/users/setInfo', {
    id,
    timezone_minutes: new Date().getTimezoneOffset(),
  }).then((response) => response.data);
};
