import Immutable from 'seamless-immutable';

import { createActions, createReducer } from 'reduxsauce';

const { Types, Creators } = createActions(
  {
    fetchDashboardData: ['id', 'elementType'],
    fetchDashboardDataSuccess: ['data'],
    setIsDashboardsLoading: ['loadings'],
    setDashboardsErrors: ['errors'],
  },
  {
    prefix: '@BE-DASHBOARDS/',
  }
);

export const DashboardsTypes = Types;
export const DashboardsActions = Creators;

export const INITIAL_STATE = Immutable({
  current: {},
  data: {},
  isDashboardsLoading: {},
  errors: {},
});

export const DashboardsSelectors = {
  getDashboards: ({ dashboards }) => dashboards.data,
  getIsDashboardsLoading: ({ dashboards }) => dashboards.isDashboardsLoading,
  getErrors: ({ dashboards }) => dashboards.errors,
};

/* ------------- REDUCERS ACTIONS ------------------- */

const fetchDashboardDataSuccess = (state, { data }) =>
  state.merge({ data: { ...state.data, ...data }, errors: [] });

const setIsDashboardsLoading = (state, { loadings }) =>
  state.merge({
    isDashboardsLoading: {
      ...state.isDashboardsLoading,
      ...loadings,
    },
  });

const setDashboardsErrors = (state, { errors }) =>
  state.merge({
    errors: {
      ...state.errors,
      ...errors,
    },
  });

export const DashboardsReducer = createReducer(INITIAL_STATE, {
  [Types.FETCH_DASHBOARD_DATA_SUCCESS]: fetchDashboardDataSuccess,
  [Types.SET_IS_DASHBOARDS_LOADING]: setIsDashboardsLoading,
  [Types.SET_DASHBOARDS_ERRORS]: setDashboardsErrors,
});
