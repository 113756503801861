import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions(
  {
    fetchOutcomesFeatureFlags: [],
    fetchOutcomesFeatureFlagsSuccess: ['features'],
    setOutcomesFeatureFlagsErrors: ['error'],
  },
  { prefix: '@BE-OUTCOMES-FEATURES/' }
);

export const OutcomesFeatureFlagsActions = Creators;
export const OutcomesFeatureFlagsTypes = Types;

export const INITIAL_STATE = Immutable({
  features: {
    ENABLE_AGGREGATE_ASSESSMENT_VIEW: false,
    ALLOW_EDIT_RESPONSE: true,
    IS_BIG_CLIENT: false,
  },
  errors: [],
});

export const OutcomesFeatureFlagsSelectors = {
  getOutcomesFeatureFlags: ({ outcomesFeatureFlags }) =>
    outcomesFeatureFlags.features,
};

/* ------------- REDUCERS ACTIONS ------------------- */
const fetchOutcomesFeatureFlagsSuccess = (state, { features }) =>
  state.merge({ features });

const setOutcomesFeatureFlagsErrors = (state, { error }) =>
  state.merge({
    errors: state.errors.concat(error),
  });

export const outcomesFeatureFlagsReducer = createReducer(INITIAL_STATE, {
  [Types.FETCH_OUTCOMES_FEATURE_FLAGS_SUCCESS]:
    fetchOutcomesFeatureFlagsSuccess,
  [Types.SET_OUTCOMES_FEATURE_FLAGS_ERRORS]: setOutcomesFeatureFlagsErrors,
});
