import { getRequest, postRequest, deleteRequest } from './base';

export const getExercisesAutoComplete = async ({
  search = '',
  page = 1,
  limit = 20,
  favorites = false,
  signal,
}) => {
  const config = {};

  if (signal) {
    config.signal = signal;
  }

  const offset = (page - 1) * limit;
  let url = `exercises/elastic/autocomplete/?offset=${offset}&limit=${limit}`;

  // search is legacy
  if (search) {
    url += `&text=${search}`;
  }

  if (favorites) {
    url += `&favorites=${favorites}`;
  }

  return getRequest(url, config).then((response) => {
    if (response.status === 200) return response.data;
    return Promise.reject(response.statusText);
  });
};

export const getExercisesElastic = async (
  search = '',
  page = 1,
  limit = 20,
  favorites = false,
  filters = [],
  alias = false,
  signal,
  shared = false
) => {
  const config = {};

  if (signal) {
    config.signal = signal;
  }

  const offset = (page - 1) * limit;
  let url = `exercises/elastic/search?limit=${offset}&size=${limit}`;

  if (search) {
    url += `&text=${search}`;
  }

  if (favorites) {
    url += `&favorites=${favorites}`;
  }

  if (alias) {
    url += `&alias=${alias}`;
  }

  if (shared) {
    url += `&shared=true`;
  }

  Object.entries(filters).forEach((entry) => {
    const [key, value] = entry;
    if (key !== favorites) {
      url += `&${key}=${value}`;
    }
  });

  return getRequest(url, config).then((response) => {
    if (response.status === 200) return response.data;
    return Promise.reject(response.statusText);
  });
};

export const toggleFavorite = async (group_id, favorited) => {
  return postRequest('favorites', { group_id, favorited }).then((response) => {
    if (response.status === 200) return response.data;
    return Promise.reject(response.statusText);
  });
};

export const getExercises = async ({
  search = '',
  page = 1,
  limit = 20,
  favorites = false,
  filters,
  alias = false,
  shared = false,
  signal,
}) =>
  getExercisesElastic(
    search,
    page,
    limit,
    favorites,
    filters,
    alias,
    signal,
    shared
  );

export const getExerciseFilters = () => {
  return getRequest(`/exercises/filters`).then((response) => {
    if (response.status === 200) return response.data;
    return Promise.reject(response.statusText);
  });
};

export const saveExerciseDefault = async (
  userId,
  exerciseId,
  name,
  description,
  restoreDefault
) => {
  return postRequest('exercises/alias/default', {
    user_id: userId,
    exercise_id: exerciseId,
    name,
    description,
    restoreDefault,
  }).then((response) => {
    if (response.status === 200) return response.data;
    return Promise.reject(response.statusText);
  });
};

export const removeCustomExercise = async (data) => {
  return deleteRequest(`/exercises/custom`, { data }).then((res) => {
    return res.data;
  });
};

export const postCustomExercise = async (body) =>
  postRequest('/exercises/share', body)
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
      throw new Error('No data returned');
    })
    .catch((e) => null);
