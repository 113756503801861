import { call, put } from 'redux-saga/effects';
import { setFavoriteEducation, getEducationsPaginated } from 'services/api/education';
import Swal from 'sweetalert2';
import { EducationsActions as Actions, EducationsTypes as Types } from './educationsReducer';

function* favoriteEducation({education}) {
  try {
    const response = yield call(setFavoriteEducation, education);

    yield put(Actions.setFavoriteEducationSuccess(response));
  } catch (error) {
    Swal.showValidationMessage(
      `Error occurred during the deactivation`
    )
  }
}

function* fetchEducations({ filters = {} }) {
  try {
    yield put(Actions.setEducationsIsLoading(true));

    const response = yield call(getEducationsPaginated, filters);

    yield put(Actions.fetchEducationsSuccess(response));
  } catch (error) {
    yield put(Actions.fetchEducationsSuccess(null));
  } finally {
    yield put(Actions.setEducationsIsLoading(false));
  }
}

export default () => ({
  [Types.SET_FAVORITE_EDUCATION]: favoriteEducation,
  [Types.FETCH_EDUCATIONS]: fetchEducations,
});
