import { put, call, select } from 'redux-saga/effects';
import {
  getCategoryAndRegionFilters,
  getProgramsFilter,
} from 'services/api/program';
import protocolsKeys from 'constants/protocolsFilters';
import {
  ProtocolsTypes as Types,
  ProtocolsActions as Actions,
  ProtocolsSelectors as Selectors,
} from './protocolsReducer';

function* fetchProtocols() {
  const search = yield select(Selectors.getSearch);
  const selectedFilters = yield select(Selectors.getSelectedFilters);

  yield put(Actions.setProtocolsIsLoading(true));
  yield put(Actions.setFiltersIsLoading(true));
  try {
    const response = yield call(
      getProgramsFilter,
      search,
      selectedFilters[protocolsKeys.category]?.value,
      selectedFilters[protocolsKeys.region]?.value
    );
    yield put(Actions.fetchProtocolsSuccess(response));
  } catch (error) {
    yield put(Actions.fetchProtocolsSuccess([]));
    console.log(error);
  } finally {
    yield put(Actions.setProtocolsIsLoading(false));
    yield put(Actions.setFiltersIsLoading(false));
  }
}

function* fetchFilters() {
  yield put(Actions.setFiltersIsLoading(true));
  try {
    const response = yield call(getCategoryAndRegionFilters);
    const defaultItem = { value: null, label: 'View all', key: -1 };

    const category = response.categories
      .filter((val) => {
        return !!val.category;
      })
      .map((val, i) => {
        return { value: val.category, label: val.category, key: i };
      });

    const region = response.regions
      .filter((val) => {
        return !!val.region;
      })
      .map((val, i) => {
        return { value: val.region, label: val.region, key: i };
      });

    category.unshift(defaultItem);
    region.unshift(defaultItem);

    yield put(
      Actions.fetchFiltersSuccess({
        [protocolsKeys.category]: category,
        [protocolsKeys.region]: region,
      })
    );
  } catch (error) {
    yield put(Actions.fetchFiltersSuccess([]));
    console.log(error);
  } finally {
    yield put(Actions.setFiltersIsLoading(false));
  }
}

function* setSelectedFilters(action) {
  const { filter } = action;

  yield put(Actions.setSelectedFiltersSuccess(filter));
  yield put(Actions.fetchProtocols());
}

export default () => ({
  [Types.FETCH_PROTOCOLS]: fetchProtocols,
  [Types.FETCH_FILTERS]: fetchFilters,
  [Types.SET_SELECTED_FILTERS]: setSelectedFilters,
});
